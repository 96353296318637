import carousel1 from '../../../images/carousel1.png';
import carousel2 from '../../../images/carousel2.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import * as c from "../../../state/variables";
import s from "./CarouselHomePage.module.css";

const routeChange = () =>{
	let path = `registration`;
	// history.push(path);
}

const CarouselHomePage = (props) => {

	return (
		<div>
			<div className="relative" onClick={event =>  window.location.href='/registration'}>
				<Carousel
					showArrows={false}
					showStatus={false}
					showIndicators={false}
					showThumbs={false}
					infiniteLoop={true}
					autoPlay={true}
				>
					<div>
						<img src={carousel1} alt={'alt'} />
					</div>
					<div>
						<img src={carousel2} alt={'alt'} />
					</div>
				</Carousel>
				<div className="sp_hide">
					<div className={s.startsText} >
						YOUR VISA PROCESS STARTS WITH <br/>
						{c.BRAND}
					</div>
					<div className="w_100 padding_db_t sp_padding_t sp_w_100 margin_a">
						<div className={s.check_btn + " w_30 sp_w_80 bg_red check_btn button_red margin_a padding_hf"}>
							<p className="center">CHECK ELIGIBILITY</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default CarouselHomePage;