import s from './StepsHeader.module.css';
// import {STEPS} from "../../../state/variables";

const StepsHeader = (props) => {
    // let allSteps = STEPS.map(d=><Step id={d.id} counter={STEPS.length} text={d.text} current={props.currentStep} />);

    return(
        <div>
            <div className="info_heading center w_100">

            </div>
            
            <div className={s.info + ' padding_hf center color_white'}>
                <div className="info_text">
                    <p className={s.fill_text}></p><b></b><p className={s.fill_text_good_luck +'color_white'}></p>
                </div> 
            </div>
           
        </div>
    )
}
export default StepsHeader;
