import React from 'react'
import Hero from './Hero.jsx';
import PaySection from './PaySection.jsx';
import Benefits from './Benefits.jsx';
import Testimonials from '../../LandingPages/Testimonials';
import Footer from '../../LandingPages/Footer';
import './MainCss.css';

function App() {
  return <div className='main'>
    <Hero />
    <PaySection />
    <Benefits />
    <Testimonials/>
      <Footer/>
  </div>;
}

export default App