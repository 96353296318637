import React from "react";
import blueSkayDesktop from "./image/benfitdesktop2Bg.jpg";
import blueSkayMobile from "./image/benefitMobile2.jpg";
import "./MainCss.css"

function Benefits() {
  return (
    <div className="h-96 mb-80 md:mb-72 mt-12">
        <img className="hidden md:block absolute z-1 object-cover w-screen" src={blueSkayDesktop} alt="sky background desktop" loading="lazy" />
      <img className="block md:hidden absolute z-1 w-screen object-cover h-fit" src={blueSkayMobile} alt="sky background mobile"  loading="lazy"/>
      <h2 className="text-center pt-10 px-3 md:pt-2 xl:pt-16 lg:text-4xl text-lg font-bold subHedline relative z-1" id="benefits">
        RESERVE YOUR COPY OF OUR CANADIAN IMMIGRATION EBOOK TODAY!
      </h2>
      <p className="text-center text-md md:text-2xl paraColor relative z-2 mt-8 px-2">
        DON'T TAKE YOUR CHANCES BY GOING IT ALONE!
        <br />
      </p>

      <p className='text-center text-lg paraColor justify-center mt-8 relative z-2 px-2'>Our comprehensive Canadian Immigration<br />
        eBook was written by experts specifically for you!</p>
      <p className='text-center text-lg paraColor justify-center mt-6 relative z-2 px-2'>
        We Know the process can be difficult,<br />so that's why we created a guide.
      </p>

      <p className='text-center text-lg paraColor justify-center mt-8 relative z-2 px-2'>
        A guide you can grab today for $9.90 <br />
        and save yourself time and money in delays.
      </p>

      <p className='text-center text-lg paraColor justify-center mt-10 relative z-2 px-1'>
        Don't wait until costly delays have you stressed out,grab our easy-to-follow guide today <br />
        and let us take the guesswork out of your Canadian immigration.
      </p>
    </div>
  );
}

export default Benefits;
