import Header from "./components/Header/Header";
import Footer from "./components/Footers/Footer";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Registration from "./components/Registration/Registration";
import HomePage from "./components/HomePage/HomePage";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsOfServices from "./components/TermsOfServices/TermsOfServices";
import Payment from "./components/Payment/Payment";
import ThankyouPage from "./components/ThankyouPage/ThankyouPage";
import MeetingsLpMain from "./components/LandingPages/MeetingLp/MeetingsLpMain";
import EbookMain from "./components/LandingPages/EbookLp/EbookMain";
import PaymentLp from "./components/LandingPages/PaymentPageLp/PaymentLp";
import StepsHeader from "./components/DifferenceComponents/StepsHeader/StepsHeader";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck ,faCircleUp } from '@fortawesome/free-solid-svg-icons';
library.add({faCheck,faCircleUp})

const App = (props) => {

  return (
    <Router>
      {/* <Header /> */}
      <div>
        <Switch>
          <Route path="/registration">
          <Header />
            <Registration state={props.state} />
            <Footer />
          </Route>
          <Route path="/terms-of-service">
          <Header />
            <TermsOfServices />
            <Footer />
          </Route>
          <Route path="/payment">
          <Header />
            <Payment state={props.state} />
          </Route>
          <Route path="/privacy-policy">
          <Header />
            <PrivacyPolicy state={props.state} />
            <Footer />
          </Route>
          <Route path="/thanks">
          <Header />
            <ThankyouPage />
            <Footer />
          </Route>
          <Route path="/thankyoupage">
          <Header />
            <ThankyouPage />
            <Footer />
          </Route>
          <Route path="/thankyoupage_plus">
          <Header />
            <ThankyouPage />
            <Footer />
          </Route>
          <Route path="/meetings"><MeetingsLpMain /></Route>
					<Route path="/ebooks"><EbookMain /></Route>
					<Route path="/payments">
             <Header />
             <StepsHeader/>
               <PaymentLp state={props.state}/>
              <Footer />
           </Route>
          <Route path="/">
          <Header />
          <HomePage />
          <Footer />
          </Route>
        </Switch>
      </div>
      {/* <Footer /> */}
    </Router>
  );
};

export default App;
