import React from "react";
import TestimonalImg1 from "../LandingPages/MeetingLp/img/Person1.png";
import TestimonalImg1new from "../LandingPages/MeetingLp/img/person1new.jpg";
import TestimonalImg2new from "../LandingPages/MeetingLp/img/person2new.jpg";
// import TestimonalImg2 from "../LandingPages/MeetingLp/img/Person2.png";
// import TestimonalImg3 from "../LandingPages/MeetingLp/img/Person3.png";
import TestimonalImg3new from "../LandingPages/MeetingLp/img/person3new.jpeg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Stars from "../LandingPages/MeetingLp/img/stars.png";
import triangle from "../LandingPages/MeetingLp/img/triangle.png";
import fbIcon from "../LandingPages/MeetingLp/img/facebookIcon.png";
import"../LandingPages/PaymentPageLp/testimonialsStyle.css";

function Testimonials() {
  
  return (
    <div className="md:mt-96" id="testimonials">
      {/* for mobile web */}
      <div className="md:hidden">
        <Carousel
          style={{ backgroundColor: "transparent" }}
          showArrows={false}
          showStatus={false}
          showIndicators={true}
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={false}
        >
          <div>
            {/* <a
              href="https://www.facebook.com/profile.php?id=100076213504503"
              target="blank"
            >{" "}
              <img
                className="TestimonialsfbImg ml-72 mt-4"
                src={fbIcon}
                alt="facebookIcon"
                style={{width:'10%'}}
              />
            </a> */}
            {/* <a href="https://www.facebook.com/profile.php?id=100076213504503" target="blank"> */}
            <img
              className="-mt-2 mr-2"
              src={[TestimonalImg1new]}
              alt="TestimonalImg"
              style={{width:'50%'}}
              />
            {/* </a> */}
               <h5 className="text-lg text-center my-2 md:my-6 blueText">
              CFIS Immigration client
            </h5>
            <p className="text-xl md:text-xl font-bold text-center">
              I am writing to express my <br />
              satification with the service <br />
              provided to my son Jahovn <br />
              Percival and I in obtaining his <br />
              student Visa..."
            </p>
            <h2 className="text-md md:text-4xl text-center">Murlita Percival</h2>
            <img
              className="starsIcons max-w-fit mr-60"
              src={Stars}
              alt="ratingIcon"
              />
          </div>
          <div>
            {/* <a
              href="https://www.facebook.com/profile.php?id=100007874587318"
              target="blank"
            >{" "}
              <img
                className="TestimonialsfbImg ml-56 mt-4"
                src={fbIcon}
                alt="facebookIcon"
                style={{width:'10%'}}
              />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100007874587318" target="blank"> */}

            <img
              className="-mt-2 mr-3"
              src={TestimonalImg2new}
              alt="TestimonalImg"
              style={{width:'50%'}}
              />
              {/* </a> */}
            <h5 className="text-lg text-center my-2 md:my-6 blueText">
              CFIS Immigration client
            </h5>
            <p className="text-xl md:text-xl text-center font-bold ">
             " I want to take this
              <br /> opportinity to thant you <br />
              ,Rachel Greay and Adam <br />
              Strauss, for their work on <br /> my case.."
            </p>
            
            <h2 className="text-md md:text-4xl text-center">Oleksandra Drachenko.</h2>
           
            <img
              className="starsIcons max-w-fit mr-60"
              src={Stars}
              alt="ratingIcon"
            />
          </div>
          <div>
            {/* <a href="https://www.facebook.com/pauu.melcon" target="blank">
              {" "}
              <img
                className="TestimonialsfbImg ml-56 mt-4"
                src={fbIcon}
                alt="facebook-Icon"
                style={{width:'10%'}}
              />
            </a> */}
            {/* <a href="https://www.facebook.com/pauu.melcon"> */}

            <img
              className="-mt-12 mr-8"
              src={[TestimonalImg3new]}
              alt="TestimonalImg"
              style={{width:'60%'}}
              />
              {/* </a> */}
               <h5 className="text-lg text-center my-2 md:my-6 blueText">
              CFIS Immigration client
            </h5>
            <p className="font-bold text-xl md:text-xl text-center">
              "I am still in the process of <br /> applying for a visa.
              <br /> Through all this,CFIS <br /> Immigration has been extremely{" "}
              <br /> professional....."
            </p>
            <h2 className=" text-md md:text-4xl text-center">Paula Mariel Rey Melcon.</h2>
            <img
              className="starsIcons max-w-fit mr-60"
              src={Stars}
              alt="ratingIcon"
            />
          
          </div>
        </Carousel>
      </div>

      {/* for desktop web */}
      <div className="md:flex flex-row flex-wrap justify-center gap-24 content-center hidden ">
        <div className="mt-8">
          <a
            href="https://www.facebook.com/profile.php?id=100076213504503"
            target="blank"
          >
            {" "}
            <img
              className="TestimonialsfbImg"
              src={fbIcon}
              alt="facebookIcon"
            />
          </a>
          <img src={[TestimonalImg1]} alt="TestimonalImg" />
          <h5 className="text-lg text-center my-6 blueText">
            CFIS Immigration client
          </h5>
          <h2 className="font-bold text-4xl text-center">Murlita P.</h2>
          <img className="starsIcons" src={Stars} alt="ratingIcon" />
          <p className="text-xl text-center">
            I am writing to express my <br />
            satification with the service <br />
            provided to my son Jahovn <br />
            Percival and I in obtaining his <br />
            student Visa...
          </p>
        </div>
        <div>
          <a
            href="https://www.facebook.com/profile.php?id=100007874587318"
            target="blank"
          >
            {" "}
            <img
              className="TestimonialsfbImg max-w-full"
              src={fbIcon}
              alt="facebookIcon"
            />
          </a>

          <img
            className="max-w-full ml-27 my-7"
            src={[TestimonalImg2new]}
            alt="TestimonalImg2"
          />
          <h5 className="text-lg text-center my-6 blueText">
            CFIS Immigration client
          </h5>
          <h2 className="font-bold text-4xl text-center">Oleksandra D.</h2>
          <img className="starsIcons" src={Stars} alt="ratingIcon" />
          <p className="text-xl text-center">
            I want to take this
            <br /> opportinity to thant you <br />
            ,Rachel Greay and Adam <br />
            Strauss, for their work on <br /> my case..
          </p>
        </div>
        <div>
          <a href="https://www.facebook.com/pauu.melcon" target="blank">
            <img
              className="TestimonialsfbImg"
              src={fbIcon}
              alt="facebookIcon"
            />
          </a>
          <img
            className="max-w-full ml-27 my-7"
            src={[TestimonalImg3new]}
            alt="TestimonalImg3"
          />
          <h5 className="text-lg text-center my-6 blueText">
            CFIS Immigration client
          </h5>
          <h2 className="font-bold text-4xl text-center">Paula M.</h2>
          <img className="starsIcons" src={Stars} alt="ratingIcon" />
          <p className="text-xl text-center">
            I am still in the process of <br /> applying for a visa.
            <br /> Through all this,Canada <br /> Direct has been extremely{" "}
            <br /> professional.....
          </p>
        </div>
      </div>
      <img src={triangle} className="triangleImg mt-16" alt="triangle" />
    </div>
  );
}

export default Testimonials;
