import React from 'react'

function footer() {
  return (
    <>
      <div className='flex flex-col'>
        <div className='grid md:grid-cols-3 footer w-full md:h-2/6 h-5/6'>
          <div className='text-white md:my-8 ml-6 md:ml-36 mr-6 mt-2'>
            <div className='items-start font-bold text-lg md:text-2xl md:my-8'>COMPANY DETAILS</div>
            <div className='items-start text-md md:my-3 mt-1'>CAIC IMMIGGRATION SL</div>
            <div className='items-start font-bold text-md md:my-3 break-words mt-12 md:mt-4'><span className='font-bold'>OUR BRANCHES:</span></div>
            <div className='items-start text-xs md:my-3break-words mt-2'><span className='font-bold'>CANADA</span>: #700-838 W Hastings Street</div>
            <div className='items-start text-xs md:my-3 break-words mt-2'>Vancouver, British Columbia V6C0A6 Canada (Legal procedures).</div>
            <div className='items-start text-xs md:my-3 break-words mt-2'><span className='font-bold'>Spain</span>:Calle Balmes 173, 08006, Barcelona, Spain.</div>
            <div className='items-start text-xs md:my-3 break-words mt-2'><span className='font-bold'>Israel</span>: Kineret 5, Bnei Brak, Israel (Payment Solutions).</div>
          </div>
          <div className='text-white font-bold md:my-8 ml-6 mr-6 md:ml-36'>
            <div className='text-md md:text-2xl font-bold md:my-8 my-2'>CONTACT</div>
            <div className='text-xs md:my-3 mt-2'>Phone:+(178)6505509</div>
            <div className='text-xs md:my-3 break-words mt-2'>Email: support@cfis-org.ca</div>
          </div>
          <div className='text-white md:my-8 ml-6 md:ml-36 mr-6'>
            <div className='text-md md:text-2xl font-bold md:my-3'>LEGAL</div>
            <div className='text-xs md:my-3 mt-2'><a href='https://cfis-org.ca/terms-of-service'>Terms of Service</a></div>
            <div className='text-xs md:my-3 mt-2'><a href='https://cfis-org.ca/privacy-policy'>Privacy Policy</a></div>
            <div className='text-xs md:my-3 mt-2'>(Legal procedures)</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default footer