import React, { useState, useEffect } from 'react';
const TestQuestions = ({ onDataCallback,secondTest,typeTest }) => {
  // Different tests
  const tests = ['Select...', 'CELPIP-G', 'IELTS', 'TEF CANADA', 'TCF CANADA'];
  // Check if secondTest is provided and decide which list to use
  const availableTests = secondTest ? secondTest : tests;
  // Different score ranges for each category within each test

  const scoreRanges = {
    'CELPIP-G': {
      speaking: [
        { value: "badvalue", label: "Select..." },
        { value: "A", label: "M,0-3" },
        { value: "B", label: "4" },
        { value: "C", label: "5" },
        { value: "D", label: "6" },
        { value: "E", label: "7" },
        { value: "F", label: "8" },
        { value: "G", label: "9" },
        { value: "H", label: "10-12" }
        // ...add other options as needed
      ],
      listening: [
        { value: "badvalue", label: "Select..." },
        { value: "A", label: "M,0-3" },
        { value: "B", label: "4" },
        { value: "C", label: "5" },
        { value: "D", label: "6" },
        { value: "E", label: "7" },
        { value: "F", label: "8" },
        { value: "G", label: "9" },
        { value: "H", label: "10-12" }
        // ...add other options as needed
      ],
      reading: [
        { value: "badvalue", label: "Select..." },
        { value: "A", label: "M,0-3" },
        { value: "B", label: "4" },
        { value: "C", label: "5" },
        { value: "D", label: "6" },
        { value: "E", label: "7" },
        { value: "F", label: "8" },
        { value: "G", label: "9" },
        { value: "H", label: "10-12" }
        // ...add other options as needed
      ],
      writing: [
        { value: "badvalue", label: "Select..." },
        { value: "A", label: "M,0-3" },
        { value: "B", label: "4" },
        { value: "C", label: "5" },
        { value: "D", label: "6" },
        { value: "E", label: "7" },
        { value: "F", label: "8" },
        { value: "G", label: "9" },
        { value: "H", label: "10-12" }
        // ...add other options as needed
      ],
    },
    'IELTS': {
      speaking: [
        { value: "badvalue", label: "Select..." },
        { value: "A", label: "0 - 3.5" },
        { value: "B", label: "4.0 - 4.5" },
        { value: "C", label: "5.0" },
        { value: "D", label: "5.5" },
        { value: "E", label: "6.0" },
        { value: "F", label: "6.5" },
        { value: "G", label: "7.0" },
        { value: "H", label: "7.5 – 9.0" }
        // ...add other options as needed
      ],
      listening: [
        { value: "badvalue", label: "Select..." },
        { value: "A", label: "0 - 3.5" },
        { value: "B", label: "4.0 - 4.5" },
        { value: "C", label: "5.0" },
        { value: "D", label: "5.5" },
        { value: "E", label: "6 .0- 7.0" },
        { value: "F", label: "7.5" },
        { value: "G", label: "8.0" },
        { value: "H", label: "8.5 – 9.0" }
        // ...add other options as needed
      ],
      reading: [
        { value: "badvalue", label: "Select..." },
        { value: "A", label: "0 - 3.0" },
        { value: "B", label: "3.5" },
        { value: "C", label: "4.0 - 4.5" },
        { value: "D", label: "5.0 - 5.5" },
        { value: "E", label: "6.0" },
        { value: "F", label: "6.5" },
        { value: "G", label: "7.0 - 7.5" },
        { value: "H", label: "8.0 – 9.0" }
        // ...add other options as needed
      ],
      writing: [
        { value: "badvalue", label: "Select..." },
        { value: "A", label: "0 - 3.5" },
        { value: "B", label: "4.0 - 4.5" },
        { value: "C", label: "5.0" },
        { value: "D", label: "5.5" },
        { value: "E", label: "6.0" },
        { value: "F", label: "6.5" },
        { value: "G", label: "7.0" },
        { value: "H", label: "7.5 – 9.0" }
        // ...add other options as needed
      ],
    },
    'TEF CANADA': {
      speaking: [
        { value: "badvalue", label: "Select..." },
        { value: "A", label: "0 - 180" },
        { value: "B", label: "181-225" },
        { value: "C", label: "226-270" },
        { value: "D", label: "271-309" },
        { value: "E", label: "310-348" },
        { value: "F", label: "349-370" },
        { value: "G", label: "371-392" },
        { value: "H", label: "393-450" }
        // ...add other options as needed
      ],
      listening: [
        { value: "badvalue", label: "Select..." },
        { value: "A", label: "0 - 144" },
        { value: "B", label: "145-180" },
        { value: "C", label: "181-216" },
        { value: "D", label: "217-248" },
        { value: "E", label: "249-279" },
        { value: "F", label: "280-297" },
        { value: "G", label: "298-315" },
        { value: "H", label: "316-360" }
        // ...add other options as needed
      ],
      reading: [
        { value: "badvalue", label: "Select..." },
        { value: "A", label: "263-300" },
        { value: "B", label: "248-262" },
        { value: "C", label: "233-247" },
        { value: "D", label: "207-232" },
        { value: "E", label: "181-206" },
        { value: "F", label: "151-180" },
        { value: "G", label: "121-150" },
        { value: "H", label: "0 - 120" }
        // ...add other options as needed
      ],
      writing: [
        { value: "badvalue", label: "Select..." },
        { value: "A", label: "0 - 180" },
        { value: "B", label: "181-225" },
        { value: "C", label: "226-270" },
        { value: "D", label: "271-309" },
        { value: "E", label: "310-348" },
        { value: "F", label: "349-370" },
        { value: "G", label: "371-392" },
        { value: "H", label: "393-450" }
        // ...add other options as needed
      ],
    },
    'TCF CANADA': {
      speaking: [
        { value: "badvalue", label: "Select..." },
        { value: "A", label: "0-3" },
        { value: "B", label: "4" },
        { value: "C", label: "4-5" },
        { value: "D", label: "6" },
        { value: "E", label: "10-11" },
        { value: "F", label: "12-13" },
        { value: "G", label: "14-15" },
        { value: "H", label: "16-20" }
        // ...add other options as needed
      ],
      listening: [
        { value: "badvalue", label: "Select..." },
        { value: "A", label: "0-330" },
        { value: "B", label: "331-368" },
        { value: "C", label: "369-397" },
        { value: "D", label: "398-457" },
        { value: "E", label: "458-502" },
        { value: "F", label: "503-522" },
        { value: "G", label: "523-548" },
        { value: "H", label: "549-699" }
        // ...add other options as needed
      ],
      reading: [
        { value: "badvalue", label: "Select..." },
        { value: "A", label: "0-341" },
        { value: "B", label: "342-374" },
        { value: "C", label: "375-405" },
        { value: "D", label: "406-452" },
        { value: "E", label: "453-498" },
        { value: "F", label: "499-523" },
        { value: "G", label: "524-548" },
        { value: "H", label: "549-699" }
        // ...add other options as needed
      ],
      writing: [
        { value: "badvalue", label: "Select..." },
        { value: "A", label: "0-3" },
        { value: "B", label: "4" },
        { value: "C", label: "4-5" },
        { value: "D", label: "6" },
        { value: "E", label: "10-11" },
        { value: "F", label: "12-13" },
        { value: "G", label: "14-15" },
        { value: "H", label: "16-20" }
        // ...add other options as needed
      ],
    },
  };

  // State for each dropdown
  const [selectedTest, setSelectedTest] = useState(availableTests[0]);
  const [testScores, setTestScores] = useState({
    testName:'badvalue',
    speaking: 'badvalue',
    listening: 'badvalue',
    reading: 'badvalue',
    writing: 'badvalue'
  });

  // Event handlers for each dropdown
  const handleTestChange = event => {
    setSelectedTest(event.target.value);
    setTestScores({
      testName:event.target.value,
      speaking: 'badvalue',
      listening: 'badvalue',
      reading: 'badvalue',
      writing: 'badvalue'
    });
  };

  const handleSpeakingChange = event => setTestScores(prevScores => ({ ...prevScores, speaking: event.target.value }));
  const handleListeningChange = event => setTestScores(prevScores => ({ ...prevScores, listening: event.target.value }));
  const handleReadingChange = event => setTestScores(prevScores => ({ ...prevScores, reading: event.target.value }));
  const handleWritingChange = event => setTestScores(prevScores => ({ ...prevScores, writing: event.target.value }));


  useEffect(() => {
      onDataCallback(testScores,typeTest);
  }, [testScores,typeTest]);
  return (
    <>
      {/* <p>Enter your test scores And second If you have:</p> */}
      <select value={selectedTest} onChange={handleTestChange} className="form-control mrgn-bttm-md">
        {availableTests.map(test => <option key={test} value={test}>{test}</option>)}
      </select>
        {testScores.testName !== 'not applicable' && (
        <>
          <label className='padding_hf_v' htmlFor="speaking">Speaking:</label>
          <select value={testScores.speaking} onChange={handleSpeakingChange} className="form-control mrgn-bttm-md">
            {scoreRanges[selectedTest]?.speaking?.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
          </select>
          <label className='padding_hf_v' htmlFor="listening">Listening:</label>
          <select value={testScores.listening} onChange={handleListeningChange} className="form-control mrgn-bttm-md">
            {scoreRanges[selectedTest]?.listening?.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
          </select>
          <label className='padding_hf_v' htmlFor="reading">Reading:</label>
          <select value={testScores.reading} onChange={handleReadingChange} className="form-control mrgn-bttm-md">
            {scoreRanges[selectedTest]?.reading?.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
          </select>
          <label className='padding_hf_v' htmlFor="writing">Writing:</label>
          <select value={testScores.writing} onChange={handleWritingChange} className="form-control mrgn-bttm-md">
            {scoreRanges[selectedTest]?.writing?.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
          </select>
        </>
      )}
    </>
  );
}

export default TestQuestions;




