import React from "react";
import EmailVarificationModal from "../EmailVarifyModal";
import leftBox from "./img/30Min.jpg";
import rightBox from "./img/60Min.jpg";
import blueelipse from "./img/Ellipse 2.png";
import orangeElipse from "./img/orangeElipse.png";
import checkImg from "./img/checkImg.png";
import paySectionBg from "./img/paySection.png";
import "../../../components/LandingPages/MeetingLp/MeetingCssLp.css";

function PaySection() {
  const userData = {
    id: "",
    email: "",
  };

  const handleClick = (event) => {
    if (event.currentTarget.getAttribute("product-id")) {
      userData.id = event.currentTarget.getAttribute("product-id");
    }
  };

  return (
    <>
      <img
        className="bgpaySection bg-no-repeat hidden md:block "
        src={paySectionBg}
        alt="program type section"
        loading="lazy"
      />
      <div className="md:my-8 my-64">
      </div>
      <div className="paySection md:mt-0 mt-96">
        <h2 className="text-center lg:py-36 pt-12 pb-8 md:text-3xl text-xl pl-2 pr-2 Sectionsheadline word-break">
        Please choose from the options below to book your consultation call.
        </h2>
        <div className="flex flex-row justify-center gap-12 md:gap-24 flex-wrap">
          <div className="md:w-96 w-72 rounded-lg shadow-lg bg-white max-w-sm">
            <img
              className="rounded-t-lg object-cover h-48 w-full"
              src={leftBox}
              alt="quastion-mark"
              loading="lazy"
            />
            <div className="text-center">
              <>
                <img
                  className="w-10 h-10 ml-4 -mt-14 absolute z-4"
                  src={blueelipse}
                  alt="blue elipse"
                />
              </>
              <div className="mt-9 md:text-3xl text-xl text-center">
                Basic Consultation
              </div>
              <div className="flex flex-col mt-10 ml-4 self-center">
                <>
                <p className="md:ml-28 ml-8 absolute text-start text-sm textInPaymentBox md:break-words leading-none">
                    30 minutes conversation.
                  </p>
                  <span>
                  <img className="md:ml-20 w-4" src={checkImg} alt="checkImg" />
                  </span>
                </>
                <div className="mt-5 ">
                  <p className="md:ml-28 ml-8 absolute text-start text-sm textInPaymentBox md:break-words leading-none">
                    Talk with a certified
                    <br className="block md:hidden" /> professional agent.
                  </p>
                  <span>
                  <img className="md:ml-20 w-4" src={checkImg} alt="checkImg" />
                  </span>
                </div>

                <div className="mt-5 mb-5">
                  <p className="md:ml-28 ml-8 absolute text-start text-sm textInPaymentBox md:break-words leading-none">
                    Understand what it take to <br /> immigrate to Canada
                  </p>
                  <span>
                    <img className="md:ml-20 w-4" src={checkImg} alt="checkImg" />
                  </span>
                </div>
              </div>

              <div className="justify-center mt-12 md:mt-44">
                <div className="flex space-x-2 justify-center">
                  <button
                    product-id="13"
                    onClick={handleClick}
                    type="button"
                    className="ml-0 md:ml-2 z-6 relative z-6 relative w-1/2 meetingBtn py-2
                    bg-blue-600 md:-mt-1
                    text-white
                    font-medium
                           text-xs
                           leading-tight
                           uppercase
                           rounded
                           shadow-md
                           hover:bg-blue-700 hover:shadow-lg
                           focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                           active:bg-blue-800 active:shadow-lg
                           transition
                           duration-150
                           ease-in-out"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Book NOW!
                  </button>
                </div>
                <div className="justify-center font-bold my-4">$29.90</div>
              </div>
            </div>
          </div>

          <div className="md:w-96 w-72 rounded-lg shadow-lg bg-white max-w-sm">
            <img
              className="rounded-t-lg object-cover h-48 w-full"
              src={rightBox}
              alt="business couple"
              loading="lazy"
            />
            <div className="text-center">
              <div className="mt-9">
                <img
                  className="w-10 h-10 ml-4 md:-mt-16 -mt-14 absolute z-4"
                  src={orangeElipse}
                  alt="orange elipse"
                />
              </div>
              <div className="mt-4 md:text-3xl text-xl text-center">
                In-Depth Consultation
              </div>
              <div className="flex flex-col mt-10 ml-4">
                <>
                  <p className=" ml-8 mt-2 absolute text-start md:ml-28 text-sm textInPaymentBox leading-none">
                    60 minutes conversation.
                  </p>
                  <span>
                    <img className="md:ml-20 w-4 mt-2" src={checkImg} alt="checkImg" />
                  </span>
                </>

                <div className="mt-3 mb-5">
                  <p className="md:ml-28  ml-8 absolute text-start text-sm textInPaymentBox md:break-words leading-none">
                    Understand what it <br />take to immigrate to Canada.
                  </p>
                  <span>
                    <img className="md:ml-20 w-4" src={checkImg} alt="checkImg" />
                  </span>
                </div>

                <div className="mt-3 ">
                  <p className="md:ml-28 ml-8 absolute text-start text-sm textInPaymentBox md:break-words leading-none">
                    Talk with a certified
                    <br className="block md:hidden" /> professional agent
                  </p>
                  <span>
                    <img className="md:ml-20 w-4" src={checkImg} alt="checkImg" />
                  </span>
                </div>
                <div className="md:mt-3 mt-8">
                  <p className="md:ml-28 ml-8 absolute text-start text-sm textInPaymentBox md:break-words leading-none">
                  Documentation overview <br className="block md:hidden" />  for  <br className="hidden md:block" /> the
                    immigrtation process.
                  </p>
                  <span>
                    <img className="md:ml-20 w-4" src={checkImg} alt="checkImg" />
                  </span>
                </div>

                <div className="mt-5 ">
                  <p className="md:ml-28 ml-8 absolute text-start text-sm textInPaymentBox md:break-words leading-none">
                    Get a full plan for you and your <br/>{" "}
                    family.
                  </p>
                  <span>
                    <img className="md:ml-20 w-4" src={checkImg} alt="checkImg" />
                  </span>
                </div>

                <div className="md:mt-5 mt-8 ">
                  <p className="md:ml-28 ml-8 absolute text-start text-sm text-smtextInPaymentBox leading-none">
                 Receive written instructions <br />
                    towards your move to Canada.
                  </p>
                  <span>
                    <img className="md:ml-20 w-4" src={checkImg} alt="checkImg" />
                  </span>
                </div>
              </div>

              <div className="justify-center mt-20">
                <div className="flex space-x-2 justify-center">
                  <button
                    product-id="14"
                    onClick={handleClick}
                    type="button"
                    className="ml-0 md:ml-2 z-6 relative z-6 relative w-1/2 meetingBtn py-2
                    bg-blue-600
                    text-white
                    font-medium
                           text-xs
                           leading-tight
                           uppercase
                           rounded
                           shadow-md
                           hover:bg-blue-700 hover:shadow-lg
                           focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                           active:bg-blue-800 active:shadow-lg
                           transition
                           duration-150
                           ease-in-out"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Book NOW!
                  </button>
                </div>
                <div className="text-center font-bold mt-4">$49.90</div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center gap-32 mt-12 ">
          <EmailVarificationModal
            data={userData}
            handleClickFunc={handleClick}
          />
          <EmailVarificationModal
            data={userData}
            handleClickFunc={handleClick}
          />
        </div>
        <div className="md:my-20 my-4 text-center">
         *Once you book an appointment with our professional agents, you will
          receive a call within 24 hours at a time of your choice. <br />
          to start YOUR immigration process officially!
        </div>
      </div>
    </>
  );
}

export default PaySection;
