import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume,faChartSimple,faChartPie,faScaleBalanced } from '@fortawesome/free-solid-svg-icons'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import BenefitsDesktop from "./img/benfitDesktopBg.jpg";
import BenefitsMobile from "./img/benefitMobile.jpg";
import '../../../components/LandingPages/MeetingLp/MeetingCssLp.css';

function Benefits() {
  
  return (
    <div>
      <img className="hidden md:block absolute z-1 object-cover" src={BenefitsDesktop} alt="sky background desktop" loading="lazy" />
      <img className="block md:hidden absolute z-1 w-screen h-[74rem]" src={BenefitsMobile} alt="sky background mobile"  loading="lazy"/>
      <h3 className="text-center pt-4 md:pt-2 xl:pt-16 lg:text-4xl text-xl text-lg font-bold subHedline relative z-1" id="benefits">
        Benefits of an Immigration Consultation
      </h3>
      <p className="text-center lg:text-2xl md:text-lg text-md paraColor mt-3 mx-2 relative z-1">
        Immigrating to Canada requires a Through knowledge of the
        <br />
        Canadian immigration process.<br className="block md:hidden" />Booking a consultation allows you:
      </p>


    <div className="grid-col-2">
      <div className="flex flex-row flex-wrap justify-center relative z-1 md:gap-x-12">
        <div className="md:boxBenfitWith">
          <FontAwesomeIcon className="iconsBenefits my-4 lg:my-4" icon={faPhoneVolume} />
          <h4 className='text-center my-1 lg:my-2 md:text-lg subHedline font-bold  relative z-1'>Talk to a Licensed Professional</h4>
          <p className='text-center md:text-md text-sm paraColor relative z-1 word-break' style={{width:'350px'}}>Our regulated Canadian Immigration Consultannts
          Know the Cnadian immigtation system inside and
          out to ensure you have a smooth immigration process.</p>
        </div>
        <div className="md:boxBenfitWith">
        <FontAwesomeIcon className="iconsBenefits my-4 lg:my-4" icon={faChartSimple} />
          <h4 className='text-center my-1 lg:my-2  md:text-lg text-md subHedline font-bold relative z-1'>Eligibility Assessment</h4>
          <p className='text-center md:text-md text-sm paraColor relative z-1 word-break ' style={{width:'350px'}}>Our experts will Determine which immigration <br />
          program(s) you qualify for and what  will give <br className="md:block hidden" />you the best chances of success.</p>
        </div>
      </div>

      <div className="flex flex-row flex-wrap justify-center relative z-1 md:gap-x-12">
        <div className="md:boxBenfitWith">
        <FontAwesomeIcon className="iconsBenefits my-4 lg:my-4" icon={faChartPie} />
          <h4 className='text-center my-1 lg:my-2 md:text-lg text-md subHedline font-bold relative z-1'>Understand Your Plan</h4>
          <p className='text-center md:text-md text-sm paraColor relative z-1 word-break ' style={{width:'350px'}}>The immigration proccess can feel ovewhelming.
          Canadin Immigration laws can be stringent and require
          a deep knowledge to<br className="hidden md:block"/> navigate.Our immigration Consultants make it their mission to guarntee you know your immigration plan.</p>
        </div>
        <div className="flex flex-row flex-wrap justify-center relative z-1 md:gap-x-12">
        <FontAwesomeIcon className="iconsBenefits my-4 lg:my-4" icon={faScaleBalanced} />
          <h4 className='text-center my-1 lg:my-2  md:text-lg text-md subHedline font-bold relative z-1'>Decide if immigration is Right For You</h4>
          <p className='text-center md:text-md text-sm paraColor relative z-1  word-break' style={{width:'350px'}}>Althought most of our clients decide to move to Canada, 
          some opt to stay in ther home countries.A consultation
          can save you hundred of thousands if you decide 
          immigration to Canada is`t for you.</p>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Benefits;
