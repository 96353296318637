import s from "../../Registration/RegistrationForm/MonthlyIncomeForm/MonthlyIncomeForm.module.css";
import React, { useState,useEffect} from "react";
import { MyComponent } from "../../../state/CountriesList";
import "react-intl-tel-input/dist/main.css";
import * as c from "../../../state/variables";
import "./payment-assessment.css";

const PaymentForm = ({caruselNext,updateUser}) => {
  
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    paymentLink: null,
  });
  const [Where_Do_You_Live, setWhere_Do_You_Live] = useState('');
  const [errors, setErrors] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // const scrollToSection = () => {
  //   // Replace 'sectionId' with the actual ID of the section you want to scroll to
  //   const sectionId = 'titleOFForm';
  //   const section = document.getElementById(sectionId);

  //   if (section) {
  //     window.scrollTo({
  //       top: section.offsetTop,
  //       behavior: 'smooth', // Optional: Add smooth scrolling animation
  //     });
  //   }
  // }


  const fetchCountry = async () => {
    try {
      const response = await fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${c.IPGEOL}`);
      const data = await response.json();
      
      // Use the functional form of setState to ensure the previous state is used
      setWhere_Do_You_Live(data.country_name);
      setFormData((prevFormData) => ({
        ...prevFormData,
        country: data.country_name,
        prefix: data.calling_code,
      }));
  
    } catch (error) {
      console.error('Error fetching country:', error);
    }
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  const CountryCode = async (id) => {
    const response = await fetch(
      c.API_ADDRESS +
        c.API_VERSION +
        "/CountryList/ContryPrefixCodeById.php?id=" +
        id
    );
    const data = await response.json();
    document.getElementById("prefix").innerHTML = data[0].code;
    document.getElementById("prefix").value = "+" + data[0].code;
  };


  const validateFormData = () => {
    let tempErrors = {};
    tempErrors.first_name = formData.first_name
      ? ""
      : "This field is required.";
    tempErrors.last_name = formData.last_name ? "" : "This field is required.";
    tempErrors.email =
      formData.email && /.+@.+\..+/.test(formData.email)
        ? ""
        : "A valid email is required.";
        setFormData({
          ...formData,
          phone: formData["prefix"] + document.getElementById("Phone"),
        });
        setErrors({
          ...tempErrors,
    });
    
    return Object.values(tempErrors).every((x) => x === "");
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFormData()) {
      // scrollToSection();
      setShowSpinner(true);
      formData["action"] = "searchUser";
      formData["Category"] = "Leads";
      formData["prefix"] = document.getElementById("prefix").value;
      formData["searchByField"] = "email";
      setFormData({ ...formData, category: formData["Category"] });
      setFormData({ ...formData, action: formData["action"] });
      setFormData({ ...formData, searchByField: formData["searchByField"] });
      setFormData({ ...formData, userEmailValue: formData["email"] });
      setFormData({ ...formData, prefix: formData["prefix"] });
      formData["phone"] = formData["prefix"] + formData["Phone"];
      setFormData({ ...formData, phone: formData["phone"] });
        const response = await fetch(
            c.API_ADDRESS + c.API_VERSION + "/functions/emailConfirmTest.php",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
        }
      );
    
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      
      const userData = await response.json();
      setFormData({ ...formData, paymentLink: userData["url"] });
      if(response){
        setTimeout(() => {
          setShowSpinner(false);
        }, 3000);
      }
    }
  };

  useEffect(() => {
    if (formData.paymentLink) {
      updateUser(formData);
      caruselNext();
    }
  }, [formData.paymentLink]);

  return (
    <>
      <div id="titleOFForm" className={s.formFrame}>
        {/* <div className={s.titleForm}>
          Immigtation Evaluation
        </div> */}
      { showSpinner && <div className="mt-4 inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
           role="status">
           <span
             className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
             >Loading...</span>
          </div>}
        <div className={s.check + " w_100 padding_db_b color_red realtive"}>
          Fill Your Personal Data
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <input
              className="inputPaymentPageStyle sp_margin_db_b inputCustomPayment"
              type="text"
              name="first_name"
              placeholder="First Name"
              value={formData.first_name}
              onChange={handleChange}
            />
            {errors.first_name && <div>{errors.first_name}</div>}
          </div>
          <div>
            <input
              className="inputPaymentPageStyle sp_margin_db_b"
              type="text"
              name="last_name"
              placeholder="Last Name"
              value={formData.last_name}
              onChange={handleChange}
            />
            {errors.last_name && <div>{errors.last_name}</div>}
          </div>
          <div>
            <input
              className="inputPaymentPageStyle sp_margin_db_b"
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <div>{errors.email}</div>}
          </div>
          <div>
            <select
              id="Where_Do_You_Live"
              className="inputPaymentPageStyle sp_margin_db_b"
              name="Where_Do_You_Live"
              value={formData.country}
              onChange={(e) => {
                CountryCode(e.target.value);
                setWhere_Do_You_Live(e.target.value);
              }}
            >
              <option value="">{Where_Do_You_Live}</option>
              {MyComponent()}
            </select>
          </div>
          <div className="w_50 center sp_w_100 padding_b inline">
            <div className="w_100">
              <input
                type="tel"
                id="prefix"
                className={s.inputFieldPrefix_LP}
                name="Prefix"
                value={formData.prefix }
                disabled={true}
              />
              <input
                id="phone"
                className={s.inputFieldPhone_LP}
                type="tel"
                name="Phone"
                placeholder="Phone Without Country Code"
                onChange={handleChange}
                require
              />
              {errors.phone && <div>{errors.phone}</div>}
            </div>
          </div>
          <div>
            <input type="submit" value="Continue" className="nextBtn" />
          </div>
        </form>
      </div>
    </>
  );
};
export default PaymentForm;
