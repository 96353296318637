import React, { useState, useCallback } from "react";
import TestQuestions from "./TestQuestions"; // Import the Question component
import SpouceQuestions from "./SpouceQuestions"; // Import the Question component
import s from "../../Registration/RegistrationForm/MonthlyIncomeForm/MonthlyIncomeForm.module.css";
import * as c from "../../../state/variables";
const questions = [
  {
    id: "q1-marital-status",
    label: "What is your marital status?",
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "Annulled Marriage" },
      { value: "B", label: "Common-Law" },
      { value: "C", label: "Divorced / Separated" },
      { value: "D", label: "Legally Separated" },
      { value: "E", label: "Married" },
      { value: "F", label: "Never Married / Single" },
      { value: "G", label: "Widowed" },
    ],
  },

  {
    id: "q2i-spouse-cit",
    label:
      "Is your spouse or common-law partner a citizen or permanent resident of Canada?",
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "No" },
      { value: "B", label: "Yes" },
    ],
  },
  {
    id: "q2ii-spouse-joining",
    label:
      "Will your spouse or common-law partner come with you to Canada?",
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "No" },
      { value: "B", label: "Yes" },
    ],
  },
  {
    id: "q3-age",
    additionalText: (
      <>
        {/* <strong>How old</strong> */}
        {/* <br /> */}
      </>
    ),
    label: "How old are you?",
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "17 years of age or les" },
      { value: "B", label: "18 years of age" },
      { value: "C", label: "19 years of age" },
      { value: "D", label: "20 years of age" },
      { value: "E", label: "21 years of age" },
      { value: "F", label: "22 years of age" },
      { value: "G", label: "23 years of age" },
      { value: "H", label: "24 years of age" },
      { value: "I", label: "25 years of age" },
      { value: "J", label: "26 years of age" },
      { value: "K", label: "27 years of age" },
      { value: "L", label: "28 years of age" },
      { value: "M", label: "29 years of age" },
      { value: "N", label: "30 years of age" },
      { value: "O", label: "31 years of age" },
      { value: "P", label: "32 years of age" },
      { value: "Q", label: "33 years of age" },
      { value: "R", label: "34 years of age" },
      { value: "S", label: "35 years of age" },
      { value: "T", label: "36 years of age" },
      { value: "U", label: "37 years of age" },
      { value: "V", label: "38 years of age" },
      { value: "W", label: "39 years of age" },
      { value: "X", label: "40 years of age" },
      { value: "Y", label: "41 years of age" },
      { value: "Z", label: "42 years of age" },
      { value: "AA", label: "43 years of age" },
      { value: "AB", label: "44 years of age" },
      { value: "AC", label: "45 years of age" },
    ],
  },
  {
    id: "q4-education",
    additionalText: (
      <>
        {/* <strong>Enter the highest level of education for which you:</strong> */}
        {/* <ul>
          <li>Earned a Canadian degree, diploma, or certificate OR</li>
          <li>
          Had an Educational Credential Assessment (ECA) if you did your study outside Canada. (ECAs must be from an approved agency, within the last five years).
          </li>
        </ul> */}
        {/* <br />
        Note: A Canadian degree, diploma, or certificate must have been earned at an accredited Canadian university, college, trade or technical school, or another
         institute in Canada. Distance learning counts for education points but not for bonus points in your profile or application. */}
      </>
    ),
    label: "What is your level of education?",
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "None or less than secondary (high school)" },
      { value: "B", label: "Secondary diploma (high school graduation)" },
      {
        value: "C",
        label:
          "One-year program at a university, college, trade or technical school, or other institute",
      },
      {
        value: "D",
        label:
          "Two-year program at a university, college, trade or technical school, or other institute)",
      },
      {
        value: "E",
        label:
          "Bachelor's degree (three or more years program at a university, college, trade or technical school, or other institute)",
      },
      {
        value: "F",
        label:
          "Two or more certificates, diplomas, or degrees. One must be for a program of three or more years.",
      },
      {
        value: "G",
        label:
          "Master's degree or professional degree needed to practice in a licensed profession (see Help)",
      },
      { value: "H", label: "Doctoral-level university degree (PhD)" },
    ],
  },
  {
    id: "q4b-education",
    additionalText: (
      <>
        {/* Note: To answer yes:
        <ul>
          <li>
          English or French as a Second Language must not have made up more than half of your study.
          </li>
          <li>
          You must not have studied under an award that required you to return to your home country after graduation to apply your skills and knowledge
          </li>
          <li>
          You must have studied at a school within Canada (foreign campuses don't count)
          </li>
          <li>
          You had to be enrolled full-time for at least eight months and have been physically present in Canada for at least eight months
          </li>
        </ul>
        <br /> */}
      </>
    ),
    label: "Have you earned a Canadian degree, diploma, or certificate?",
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "No" },
      { value: "B", label: "Yes" },
    ],
  },
  {
    id: "q4c-education",
    label: "Choose the best answer to describe this level of education.",
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "Secondary (high school) or less" },
      { value: "B", label: "One- or two-year diploma or certificate" },
      {
        value: "C",
        label:
          "Degree, diploma, or certificate of three years or longer Master’s, professional, or doctoral degree of at least one academic yea",
      },
    ],
  },
  {
    id: "q5-ol",
    label:"Official languages: Canada's official languages are English and French.",
    additionalText: (
      <>
        {/* <p>
          You need to submit language test results that are less than two years
          old for all programs under Express Entry, even if English or French is
          your first language.
        </p>
        <br />
        <p>i. Are your test results less than two years old?</p>
        <br /> */}
      </>
    ),
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "No" },
      { value: "B", label: "Yes" },
    ],
  },
  {
    id: "q5i-a-fol",
    label:
      "ii)Which language test did you take for your first official language?",
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "CELPIP-G" },
      { value: "B", label: "ILETS" },
      { value: "C", label: "TEF CANADA" },
      { value: "D", label: "TCF CANADA" },
    ],
  },
  {
    id: "q6-work-xp",
    label:
      "In the last ten years, how many years of skilled work experience in Canada do you have?",
    additionalText: (
      <>
        {/* <p>
        It must have been paid and full-time (or an equal amount in part-time).
        </p>
        <p>
          <strong>Note:</strong> In Canada, the National Occupational
          Classification (NOC) is the official list of all the jobs in the
          Canadian labour market. It describes each job according to skill type,
          group and level.
        </p>
        <p>&quot;Skilled work&quot; in the NOC is:</p>
        <ul>
          <li>Managerial jobs (NOC Skill Level 0)</li>
          <li>professional jobs (NOC Skill Type A)</li>
          <li>
          technical jobs, and skilled trades/manual work (NOC Skill Type B).
          </li>
        </ul>
        <p>
          {" "}
          If you aren't sure of the NOC level for this job, you can{" "}
          <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/find-national-occupation-code.html">
            find your NOC
          </a>
          .
        </p> */}
      </>
    ),
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "None or less than a year" },
      { value: "B", label: "1 year" },
      { value: "C", label: "2 years" },
      { value: "D", label: "3 years" },
      { value: "E", label: "4 years" },
      { value: "F", label: "5 years or more" },
    ],
  },
  {
    id: "q6ii-foreign",
    label:
      "In the last 10 years, how many total years of foreign skilled work experience do you have?",
    additionalText: (
      <>
        {/* <p>
        In the last 10 years, how many total years of foreign skilled work experience do you have? It must have been paid, full-time (or an equal amount in part-time), 
        and in only one occupation (NOC skill type 0, A, or B)

        </p> */}
      </>
    ),
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "None or less than a year" },
      { value: "B", label: "1 year" },
      { value: "C", label: "2 years" },
      { value: "D", label: "3 years or more" },
    ],
  },
  {
    id: "q7-certificate",
    label:"Do you have a certificate of qualification from a Canadian province, territory or federal body?",
    additionalText: (
      <>
        {/* <p>
          <strong>Note: </strong>A certificate of qualification allows people to work in some skilled trades in Canada. Only the provinces, 
          territories, and a federal body can issue these certificates. 
          To obtain one, a person must have their training, trade experience, and skills assessed and then pass a certification exam.
        </p>
        <p>
          {" "}
          People usually have to go to the province or territory to be assessed. 
          They may also need experience and training from an employer in Canada.{" "}
        </p>
        <p>This isn't the same as a nomination from a province or territory.</p> */}
      </>
    ),
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "No" },
      { value: "B", label: "Yes" },
    ],
  },
  {
    id: "q8-offer",
    label:  "Do you have a valid job offer supported by a Labour Market Impact Assessment",
    additionalText: (
      <>
        {/* <p>A valid job offer must be</p>
        <ul>
          <li>full-time</li>
          <li>
          skilled job listed under Skill Type 0, Skill Level A, or Skill Level B in the 2011 National Occupational Classification.
          </li>
          <li>
          It must also be supported by a Labour Market Impact Assessment (LMIA) or be exempt from requiring one
          </li>
          <li>This offer is valid for one year from the time you become a permanent resident.</li>
        </ul>
        <p>Note that a job offer is not considered valid if your employer is :</p>
        <ul>
          <li>an embassy, high commission, or consulate in Canada or</li>
          <li> if they are on the list of ineligible employers.</li>
        </ul>
        <p>
        Whether an offer is valid or not also depends on different factors, which vary depending on your specific case.. You can find a full list of criteria for valid job offers  {" "}
          <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/documents/offer-employment.html">
          </a>
          .
        </p> */}
      </>
    ),
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "YES" },
      { value: "B", label: "NO" },
    ],
  },
  {
    id: "q8-noc",
    label: "Which NOC skill type or level is the job offer?",
    additionalText: (
      <>
        {/* <p>You can use our online tool to find out if you don’t know.</p>{" "} */}
      </>
    ),
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "NOC Skill Type 00" },
      {
        value: "B",
        label: "NOC Skill Level A or B or	any Type 0 other than 00",
      },
      { value: "C", label: "NOC Skill Level C or D" },
    ],
  },
  {
    id: "q9-nomination",
    label:
      "Do you have a nomination certificate from a province or territory?",
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "No" },
      { value: "B", label: "Yes" },
    ],
  },
  {
    id: "q10-sibling",
    label:
      "Do you or your spouse or common law partner (if they will come with you to Canada) have at least one brother or sister living in Canada who is a citizen or permanent resident?",
    additionalText: (
      <>
        {/* <p>
          <strong>Note: to answer yes, the brother or sister must be:</strong>
        </p>
        <ul>
          <li>18 years old or older</li>
          <li>
            related to you or your partner by blood, marriage, common-law
            partnership or adoption
          </li>
          <li>have a parent in common with you or your partner</li>
        </ul>
        <p>A brother or sister is related to you by:</p>
        <ul>
          <li>blood (biological)</li>
          <li>adoption</li>
          <li>marriage (step-brother or step-sister)</li>
        </ul> */}
      </>
    ),
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "No" },
      { value: "B", label: "Yes" },
    ],
  },
  {
    id: "q5iii",
    label:
      "Do you or your spouse or common law partner (if they will come with you to Canada) have at least one brother or sister living in Canada who is a citizen or permanent resident?",
    additionalText: (
      <>
        {/* <p>
          <strong>Note: to answer yes, the brother or sister must be:</strong>
        </p>
        <ul>
          <li>18 years old or older</li>
          <li>
            related to you or your partner by blood, marriage, common-law
            partnership or adoption
          </li>
          <li>have a parent in common with you or your partner</li>
        </ul>
        <p>A brother or sister is related to you by:</p>
        <ul>
          <li>blood (biological)</li>
          <li>adoption</li>
          <li>marriage (step-brother or step-sister)</li>
        </ul> */}
      </>
    ),
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "No" },
      { value: "B", label: "Yes" },
    ],
  },
];


//map for each question on what next question should be base on previos question
const nextQuestionMapping = {
  "q1-marital-status": {
    A: "q3-age",
    B: "q2i-spouse-cit",
    C: "q3-age",
    D: "q3-age",
    E: "q2i-spouse-cit",
    F: "q3-age",
    G: "q3-age",
  },
  "q2i-spouse-cit": {
    A: "q2ii-spouse-joining",
    B: "q3-age",
  },
  "q2ii-spouse-joining": {
    A: "q3-age",
    B: "q3-age",
  },
  "q3-age": {
    A: "q4-education",
    B: "q4-education",
    C: "q4-education",
    D: "q4-education",
    E: "q4-education",
    F: "q4-education",
    G: "q4-education",
    H: "q4-education",
    I: "q4-education",
    J: "q4-education",
    K: "q4-education",
    L: "q4-education",
    M: "q4-education",
    N: "q4-education",
    O: "q4-education",
    P: "q4-education",
    Q: "q4-education",
    R: "q4-education",
    S: "q4-education",
    T: "q4-education",
    U: "q4-education",
    V: "q4-education",
    W: "q4-education",
    X: "q4-education",
    Y: "q4-education",
    Z: "q4-education",
    AA: "q4-education",
    AB: "q4-education",
    AC: "q4-education",
  },
  "q4-education": {
    A: "q4b-education",
    B: "q4b-education",
    C: "q4b-education",
    D: "q4b-education",
    E: "q4b-education",
    F: "q4b-education",
    G: "q4b-education",
    H: "q4b-education",
  },
  "q4b-education": {
    A: "q6-work-xp",
    B: "q4c-education",
  },
  "q4c-education": {
    A: "q6-work-xp",
    B: "q6-work-xp",
    C: "q6-work-xp",
  },
  "q5-ol": {
    B: "q6-work-xp",
    A: "q6-work-xp",
  },
  "q6-work-xp": {
    A: "q6ii-foreign",
    B: "q6ii-foreign",
    C: "q6ii-foreign",
    D: "q6ii-foreign",
    E: "q6ii-foreign",
    F: "q6ii-foreign",
  },
  "q6ii-foreign": {
    A: "q7-certificate",
    B: "q7-certificate",
    C: "q7-certificate",
    D: "q7-certificate",
  },

  "q7-certificate": {
    A: "q8-offer",
    B: "q8-offer",
  },

  "q8-offer": {
    A: "q9-nomination",
    B: "q8-noc",
  },
  "q8-noc": {
    A: "q9-nomination",
    B: "q9-nomination",
    C: "q9-nomination",
  },
  "q9-nomination": {
    A: "q10-sibling",
    B: "q10-sibling",
  },
  "q10-sibling": {
    A: "q5-ol",
    B: "q5-ol",
  }
  // Add more mappings if needed
};
//array option for second questionier
const englishTest = ['Select...', 'CELPIP-G', 'ELETS', 'not applicable']
const FrTest = ["Select...", "TEF CANADA", "TCF CANADA", 'not applicable'];


const SelectQuestions = (fullUserData) => {
  const [answers, setAnswers] = useState({});
  const [shownQuestions, setShownQuestions] = useState([questions[0].id]);//show q 1by 1
  const [gotTestAnswears, setgotTestAnswears] = useState(false);//test 1
  const [gotTestAnswears2, setgotTestAnswears2] = useState(false);//test 2
  const [showSpouce, setshowSpouce] = useState(false);// show spouceQuestion
  // const [spouceQuestionier, setSpouceQuestionier] = useState(false);//
  const [secondTestRes, setSecondTest] = useState(null);
  const [isButtonDisabled,setButton] = useState(true);
  const [allAnswers, setAllAnswers] = useState({ btn_and_info: fullUserData.fullUserData, userQuestionier: null, userTestQuestionier_one: null, userTestQuestionier_two: null, spouseQuestionier: null });//all data for assment and payment link
  const [display, setDisplay] = useState({
    first: 'block',
    second: 'none'
  });
  const [clicked, setClicked] = useState(false);

  const createAssessment = async (e) => {
    if (e) {
      e.preventDefault();
    }
    console.log('createAssessment');
    //send for create assment 
    const response = await fetch("https://www.canada-direct.com/cfisLPAssessment/functions_assessment.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(allAnswers),
      }
    );
    // console.log(response);
  }

  const changeSection = () => {
    console.log('changeSection func');
    createAssessment();
    if (!clicked) {
      setDisplay({
        first: 'none',
        second: 'block'
      });
      setClicked(true);
      scrollToSection()
    }
  }


  //get the question answear
  const handleAnswer = (id, event) => {
    const selectedOption = event.target.value;
    if (id === "q2ii-spouse-joining" && selectedOption === "B") {
      setshowSpouce(true);
    }

    if (id === "q5-ol" && selectedOption === "B") {
      setgotTestAnswears(true);
    }

    setAnswers({
      ...answers,
      [id]: selectedOption,
    });

    //show next question depends on previous answear
    const nextQuestionId = nextQuestionMapping[id]?.[selectedOption];
    if (nextQuestionId && !shownQuestions.includes(nextQuestionId)) {
      setShownQuestions(shownQuestions.concat(nextQuestionId));
    }

    if (id === "q10-sibling" && selectedOption !== 'badvalue') {
      setButton(false);
      console.log('q10-sibling - working')
      setAllAnswers(prevState => ({
        ...prevState,
        userQuestionier: answers
      }));
    }
  };

  const updateTestResult = useCallback((testQ, typeTest) => {
    //first Lengueage Test
    if (typeTest === 'one') {
      setAllAnswers(prevData => ({ ...prevData, userTestQuestionier_one: testQ }));
      if (["CELPIP-G", "ILETS"].includes(testQ.testName)) {
        setSecondTest(FrTest);
      } else if (["TEF CANADA", "TCF CANADA"].includes(testQ.testName)) {
        setSecondTest(englishTest);
      }
      setgotTestAnswears2(true);
    }
    //second client Lenguege test
    if (typeTest === 'two') {
      setAllAnswers(prevData => ({ ...prevData, userTestQuestionier_two: testQ }));
    }
  }, []);

  const handleSpouceData = (spouceData) => {
    setAllAnswers(prevData => ({ ...prevData, spouseQuestionier: spouceData }));
  };

  const scrollToSection = () => {
    // Replace 'sectionId' with the actual ID of the section you want to scroll to
    const sectionId = 'topTitle';
    const section = document.getElementById(sectionId);

    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth', // Optional: Add smooth scrolling animation
      });
    }
  }


  const checkQuestionier = () => {
    if(!isButtonDisabled){
      switch (true) {
        case (
          //not maried 
          ((allAnswers.userQuestionier['q1-marital-status'] !== 'B' || allAnswers.userQuestionier['q1-marital-status'] !== 'E') ||
            (  //maried
              (allAnswers.userQuestionier['q1-marital-status'] === 'B' || allAnswers.userQuestionier['q1-marital-status'] === 'E') &&
              ((allAnswers.userQuestionier['q2i-spouse-cit'] === 'B') || (allAnswers.userQuestionier['q2i-spouse-cit'] === 'A' &&
                allAnswers.userQuestionier['q2ii-spouse-joining'] === 'A')) &&
              //no test
              allAnswers.userQuestionier['q10'] !== null && allAnswers.userTestQuestionier_one === null && allAnswers.spouseQuestionier === null))):
          // console.log("Matched Case 1");
          changeSection();
          break;
        case (
          //not maried 1 test
          ((allAnswers.userQuestionier['q1-marital-status'] !== 'B' || allAnswers.userQuestionier['q1-marital-status'] !== 'E') ||
            (  //maried
              (allAnswers.userQuestionier['q1-marital-status'] === 'B' || allAnswers.userQuestionier['q1-marital-status'] === 'E') &&
              ((allAnswers.userQuestionier['q2i-spouse-cit'] === 'B') || (allAnswers.userQuestionier['q2i-spouse-cit'] === 'A' &&
                allAnswers.userQuestionier['q2ii-spouse-joining'] === 'A')) &&
              allAnswers.userQuestionier['q10'] !== null &&
              allAnswers.userTestQuestionier_one !== null &&
              (allAnswers.userTestQuestionier_one['testName'] !== 'badvalue' &&
                allAnswers.userTestQuestionier_one['speaking'] !== 'badvalue' &&
                allAnswers.userTestQuestionier_one['listening'] !== 'badvalue' &&
                allAnswers.userTestQuestionier_one['reading'] !== 'badvalue' &&
                allAnswers.userTestQuestionier_one['writing'] !== 'badvalue') &&
              allAnswers.userTestQuestionier_two['testName'] === 'not applicable' && allAnswers.spouseQuestionier === null))):
          // console.log("Matched Case 2");
          changeSection();
          break;
  
        //not maried 2 test
        case ((allAnswers.userQuestionier['q1-marital-status'] !== 'B' || allAnswers.userQuestionier['q1-marital-status'] !== 'E') ||
          (  //maried 2 test
            (allAnswers.userQuestionier['q1-marital-status'] === 'B' || allAnswers.userQuestionier['q1-marital-status'] === 'E') &&
            ((allAnswers.userQuestionier['q2i-spouse-cit'] === 'B') || (allAnswers.userQuestionier['q2i-spouse-cit'] === 'A' &&
              allAnswers.userQuestionier['q2ii-spouse-joining'] === 'A')) &&
            allAnswers.userTestQuestionier_one !== null &&
            (allAnswers.userTestQuestionier_one['testName'] !== 'badvalue' &&
              allAnswers.userTestQuestionier_one['speaking'] !== 'badvalue' &&
              allAnswers.userTestQuestionier_one['listening'] !== 'badvalue' &&
              allAnswers.userTestQuestionier_one['reading'] !== 'badvalue' &&
              allAnswers.userTestQuestionier_one['writing'] !== 'badvalue') &&
            (allAnswers.userTestQuestionier_two['testName'] !== 'badvalue' &&
              allAnswers.userTestQuestionier_two['speaking'] !== 'badvalue' &&
              allAnswers.userTestQuestionier_two['listening'] !== 'badvalue' &&
              allAnswers.userTestQuestionier_two['reading'] !== 'badvalue' &&
              allAnswers.userTestQuestionier_two['writing'] !== 'badvalue') &&
            allAnswers.userQuestionier['q10'] !== null && allAnswers.spouseQuestionier === null)):
          // console.log("Matched Case 3");
          changeSection();
          break;
  
        //maried spouce question 0 test user,spouce question no test 
        case ((allAnswers.userQuestionier['q1-marital-status'] === 'B' ||
          allAnswers.userQuestionier['q1-marital-status'] === 'E') &&
          allAnswers.userQuestionier['q10'] !== null &&
          allAnswers.spouseQuestionier !== null &&
          allAnswers.spouseQuestionier.selectQuestion['q11-s-work-xp'] !== null &&
          allAnswers.spouseQuestionier.testSpouceAnswears === null &&
          allAnswers.userTestQuestionier_one === null):
          // console.log("Matched Case 4");
          changeSection();
          break;
  
        //maried spouce question 1 test user,spouce question no test 
        case ((allAnswers.userQuestionier['q1-marital-status'] === 'B' ||
          allAnswers.userQuestionier['q1-marital-status'] === 'E') &&
          allAnswers.userQuestionier['q10'] !== null &&
          allAnswers.spouseQuestionier !== null &&
          allAnswers.spouseQuestionier.selectQuestion['q11-s-work-xp'] !== null &&
          allAnswers.spouseQuestionier.testSpouceAnswears === null &&
          allAnswers.userTestQuestionier_one !== null &&
          (allAnswers.userTestQuestionier_one['testName'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_one['speaking'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_one['listening'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_one['reading'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_one['writing'] !== 'badvalue') &&
          allAnswers.userTestQuestionier_two['testName'] !== 'not applicable'):
          // console.log("Matched Case 5");
          changeSection();
          break;
  
        //maried spouce question no 2 test user,maried spouce question ,no spouce test
        case ((allAnswers.userQuestionier['q1-marital-status'] === 'B' ||
          allAnswers.userQuestionier['q1-marital-status'] === 'E') &&
          allAnswers.spouseQuestionier !== null &&
          allAnswers.spouseQuestionier.selectQuestion['q11-s-work-xp'] !== null &&
          allAnswers.spouseQuestionier.testSpouceAnswears === null &&
          allAnswers.userTestQuestionier_one !== null &&
          (allAnswers.userTestQuestionier_one['testName'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_one['speaking'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_one['listening'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_one['reading'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_one['writing'] !== 'badvalue') &&
          (allAnswers.userTestQuestionier_two['testName'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_two['speaking'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_two['listening'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_two['reading'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_two['writing'] !== 'badvalue') &&
          allAnswers.userQuestionier['q10'] !== null):
          // console.log("Matched Case 6");
          changeSection();
          break;
  
        //maried spouce question  test ,user question no test 
        case ((allAnswers.userQuestionier['q1-marital-status'] === 'B' ||
          allAnswers.userQuestionier['q1-marital-status'] === 'E') &&
          allAnswers.userQuestionier['q10'] !== null &&
          allAnswers.userTestQuestionier_one === null &&
          allAnswers.spouseQuestionier !== null &&
          allAnswers.spouseQuestionier.selectQuestion['q11-s-work-xp'] !== null &&
          allAnswers.spouseQuestionier.testSpouceAnswears !== null && (allAnswers.testSpouceAnswears['testName'] !== 'badvalue' &&
            allAnswers.testSpouceAnswears['speaking'] !== 'badvalue' &&
            allAnswers.testSpouceAnswears['listening'] !== 'badvalue' &&
            allAnswers.testSpouceAnswears['reading'] !== 'badvalue' &&
            allAnswers.testSpouceAnswears['writing'] !== 'badvalue')
        ):
          // console.log("Matched Case 7");
          changeSection();
          break;
  
        //maried spouce question  test ,user question 1 test 
        case ((allAnswers.userQuestionier['q1-marital-status'] === 'B' ||
          allAnswers.userQuestionier['q1-marital-status'] === 'E') &&
          allAnswers.userQuestionier['q10'] !== null &&
          allAnswers.userTestQuestionier_one !== null &&
          (allAnswers.userTestQuestionier_one['testName'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_one['speaking'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_one['listening'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_one['reading'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_one['writing'] !== 'badvalue') &&
          allAnswers.spouseQuestionier !== null &&
          allAnswers.spouseQuestionier.selectQuestion['q11-s-work-xp'] !== null &&
          allAnswers.spouseQuestionier.testSpouceAnswears !== null && (allAnswers.testSpouceAnswears['testName'] !== 'badvalue' &&
            allAnswers.testSpouceAnswears['speaking'] !== 'badvalue' &&
            allAnswers.testSpouceAnswears['listening'] !== 'badvalue' &&
            allAnswers.testSpouceAnswears['reading'] !== 'badvalue' &&
            allAnswers.testSpouceAnswears['writing'] !== 'badvalue') &&
          allAnswers.userTestQuestionier_two['testName'] !== 'not applicable'):
          // console.log("Matched Case 8");
          changeSection();
          break;
  
  
        //maried spouce question+test spouce,2 user test
        case ((allAnswers.userQuestionier['q1-marital-status'] === 'B' ||
          allAnswers.userQuestionier['q1-marital-status'] === 'E') &&
          allAnswers.userQuestionier['q10'] !== null &&
          allAnswers.userTestQuestionier_one !== null &&
          (allAnswers.userTestQuestionier_one['testName'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_one['speaking'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_one['listening'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_one['reading'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_one['writing'] !== 'badvalue') &&
          allAnswers.spouseQuestionier !== null &&
          allAnswers.spouseQuestionier.selectQuestion['q11-s-work-xp'] !== null &&
          allAnswers.spouseQuestionier.testSpouceAnswears !== null && (allAnswers.testSpouceAnswears['testName'] !== 'badvalue' &&
            allAnswers.testSpouceAnswears['speaking'] !== 'badvalue' &&
            allAnswers.testSpouceAnswears['listening'] !== 'badvalue' &&
            allAnswers.testSpouceAnswears['reading'] !== 'badvalue' &&
            allAnswers.testSpouceAnswears['writing'] !== 'badvalue') &&
          (allAnswers.userTestQuestionier_two['testName'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_two['speaking'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_two['listening'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_two['reading'] !== 'badvalue' &&
            allAnswers.userTestQuestionier_two['writing'] !== 'badvalue')):
          // console.log("Matched Case 9");
          changeSection();
          break;
  
        default:
          console.log("No matching case found");
      }
    }

    else{
      alert("You have not answered all the questions. Please answer all the questions")
    }
  }

  return (
    <>
      <div className="App" style={{ display: display.first }} >
        <h1 id='topTitle' className="mb-4 text-xl font-bold color_red">Complete the questionnaire</h1>
        {shownQuestions.map((questionId) => {
          const question = questions.find((q) => q.id === questionId);
          return (
            <div className="my-4" key={question.id}>
              <label className='padding_b' htmlFor={question.id}>{question.label}</label>
              {question.additionalText && <div>{question.additionalText}</div>}
              <select
                className="form-select"
                id={question.id}
                onChange={(event) => handleAnswer(question.id, event)}
                value={answers[question.id] || "badvalue"}
              >
                {question.options.map((option, j) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          );
        })}
        {gotTestAnswears && (
          <TestQuestions onDataCallback={updateTestResult} typeTest={'one'} />
        )}
        {gotTestAnswears && gotTestAnswears2 && (<div>
          <p>
          Do you have other language results?

          {/* </p>
          <p>
            If so, which language test did you take for your second official language?
          </p>
          <p>
            Test results must be less than two years old. */}
          </p>
        </div>)}

        {gotTestAnswears && gotTestAnswears2 && (
          <TestQuestions className="secondTest" onDataCallback={updateTestResult} secondTest={secondTestRes} typeTest={'two'} />
        )}


        {showSpouce && (
          <SpouceQuestions onSpouceDataUpdate={handleSpouceData} />)}
        {/* <button type="button" id="procceedPayment" className="nextBtn" onClick={checkQuestionier} disabled >Procced for Payment</button> */}
        <button type="button" id="checkQuestionier" className="nextBtn" onClick={checkQuestionier} >Procced for Payment</button>
      </div>
      <div style={{ display: display.second }} >
        <iframe
          src={fullUserData.fullUserData.userInfo["paymentLink"]}
          title="safeChargeLink"
          className={s.safeChargeIframe}>
        </iframe>
      </div>
    </>
  );
};

export default SelectQuestions;

