import React, { useState ,useEffect} from 'react';
import TestQuestions from "./TestQuestions"; // Import the Question component

//spouce questions
const SpouceQuestion = [
  {
    id: "q10-s-education",
    label:
      "What is the highest level of education for which your spouse or common-law partner's has:",
    additionalText: (
      <>
        {/* <ul>
          <li><strong>earned a Canadian degree, diploma or certificate; or</strong></li>
          <li><strong>had an Educational Credential Assessment (ECA)? (ECAs must be from an approved agency, in the last five years)</strong></li>
        </ul>
        <p>To get the correct number of points, make sure you choose the answer that best reflects your case. For example:</p>
        <p>If you have TWO Bachelor’s degrees, or one Bachelor’s AND a two year college diploma, choose – “Two or more certificates, diplomas, or degrees. One must be for a program of three or more years.”</p> */}
      </>
    ),
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "None, or less than secondary (high school)" },
      { value: "B", label: "Secondary diploma (high school graduation)" },
      { value: "C", label: "One-year program at a university, college, trade or technical school, or other institute" },
      { value: "D", label: "Two-year program at a university, college, trade or technical school, or other institute" },
      { value: "E", label: "Bachelor's degree (three or more year program at a university, college, trade or technical school, or other institute)" },
      { value: "F", label: "Two or more certificates, diplomas or degrees. One must be for a program of three or more years" },
      { value: "G", label: "Master's degree, or professional degree needed to practice in a licensed profession" },
      { value: "H", label: "Doctoral level university degree (PhD)" },
    ],
  },
  {
    id: "q11-s-work-xp",
    label:
      "In the last ten years, how many years of skilled work experience in Canada does your spouse/common-law partner have?",
    additionalText: (
      <>
        {/* <p>It must have been paid, full-time (or an equal amount in part-time), and in one or more NOC 0, A or B jobs.</p> */}
      </>
    ),
    options: [
      { value: "badvalue", label: "Select..." },
      { value: "A", label: "None or less than a year" },
      { value: "B", label: "1 year" },
      { value: "C", label: "2 years" },
      { value: "D", label: "3 years" },
      { value: "E", label: "4 years" },
      { value: "F", label: "5 years or more" },
    ],
  },
]

//mapping questions
const nextSpouceQuestionMapping = {
  "q10-s-education": {
    A: "q11-s-work-xp",
    B: "q11-s-work-xp",
    C: "q11-s-work-xp",
    D: "q11-s-work-xp",
    E: "q11-s-work-xp",
    F: "q11-s-work-xp",
    G: "q11-s-work-xp",
    H: "q11-s-work-xp",
  },
}

function SpouceQuestions({onSpouceDataUpdate}) {
  const [spouceAnswers, setSpouceAnswers] = useState({});
  const [spouceAllAnswers, setAllSpouceAnswers] = useState({selectQuestion:null,testSpouceAnswears:null});
  const [shownQuestions, setShownQuestions] = useState([SpouceQuestion[0].id]);
  const [gotSpouceTestAnswears, setgotTestAnswears] = useState(false);
  const [testAnswears, setTestAnswears] = useState(null);

  const handleTestCallback = (testQ) => {
    setTestAnswears(testQ);
    setAllSpouceAnswers(prevData => ({ ...prevData, testSpouceAnswears: testQ }));
    setAllSpouceAnswers(prevData => ({ ...prevData, selectQuestion: spouceAnswers }));
  };

  //get the question answear
  const handleAnswer = (id, event) => {
    const selectedOption = event.target.value;
    
    setSpouceAnswers({
      ...spouceAnswers,
      [id]: selectedOption,
    });
    if (id === "q11-s-work-xp") {
      setgotTestAnswears(true);
    }

    //show next question depends on previous answear
    const spouceNextQuestionId = nextSpouceQuestionMapping[id]?.[selectedOption];
    if (spouceNextQuestionId && !shownQuestions.includes(spouceNextQuestionId)) {
      setShownQuestions(shownQuestions.concat(spouceNextQuestionId));
    }

  };
  

  useEffect(() => {
    onSpouceDataUpdate(spouceAllAnswers);
  }, [spouceAllAnswers]);

  return (
    <>
      <div className="App">
        {shownQuestions.map((questionId) => {
          const question = SpouceQuestion.find((q) => q.id === questionId);
          return (
            <div className="my-4" key={question.id}>
              <label className='padding_db_b' htmlFor={question.id}>{question.label}</label>
              {question.additionalText && <div>{question.additionalText}</div>}
              <select
                className="form-select"
                id={question.id}
                onChange={(event) => handleAnswer(question.id, event)}
                value={spouceAnswers[question.id] || "badvalue"}
              >
                {question.options.map((option, j) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          );
        })}
      { gotSpouceTestAnswears && ( <TestQuestions  onDataCallback={handleTestCallback}/>)}
      </div>
    </>
  );
}

export default SpouceQuestions