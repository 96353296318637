import React from 'react';
import HamburgerMenu  from '../HamburgerMenu';
import bgHero from "./image/heroImage.jpg"
function Hero() {
  return (
    <>
      <div>
        <img
          className="bg absolute hidden md:block"
          src={bgHero}
          alt="Woomen near sea"
          loading="lazy"
        />
        <img
          className="object-cover h-64 w-full absolute block md:hidden"
          src={bgHero}
          alt="Woomen near sea"
          loading="lazy"
        />

        <HamburgerMenu/>

        <div className="flex flex-col md:heroText ml-4 md:ml-40 lg:ml-96 xl:mt-48 lg:mt-24 md:mt-20 mt-2 z-7 relative">
          <h1 className="xl:text-5xl lg:text-3xl text-lg font-bold text-white">
          LEARN EVERTHING <br />
          THERE IS TO KNOW <span className="subHedline"><br />ABOUT CANADA</span>
          </h1>
          <p className="xl:text-2xl text-md text-white xl:mt-4 pt-2 mb-7">
             Your first step towards immigration.
          </p>
        </div>
      </div>
    </>
  );
}

export default Hero;
