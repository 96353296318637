import React from "react";
import "./index.css";
import "./flexy.css";
import ReactDOM from "react-dom";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Redirect } from "react-router-dom";
import store from "./state/state";
import * as c from "./state/variables";
import ReactPixel from "react-facebook-pixel";
import { hotjar } from 'react-hotjar';
// Initialization for ES Users

let pixel = c.FACEBOOK_PIXEL_ID;
const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init(pixel, advancedMatching, options);
hotjar.initialize("3427465", '6');
ReactPixel.pageView(); // For tracking page view
document.title = "CFIS Immigration"
let rerenderEntireTree = () => {
  ReactDOM.render(
    <BrowserRouter>
        <App state={store._state} />
    </BrowserRouter>,
    document.getElementById("root")
  );
};
rerenderEntireTree(store.getState());

store.subscribe(rerenderEntireTree);
