import React from "react";
import payTag from "./image/payTag.png";
import bookToronto from "./image/TorontoBook.png";
import bookVancuver from "./image/VanBook.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faSchool,
  faSuitcaseMedical,
  faSackDollar,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import EmailVarificationModal from "../EmailVarifyModal";
import paymenentBgDesktopImg from "./image/paysectionEbook.png";
import "./MainCss.css";

function PaySection() {
  const userData = {
    id: "",
    email: "",
  };

  const handleClick = (event) => {
    if (event.currentTarget.getAttribute("product-id")) {
      userData.id = event.currentTarget.getAttribute("product-id");
    }
  };

  return (
    <div className="h-3/4">
      <img
        className="hidden md:block absolute z-1 object-cover w-full"
        src={paymenentBgDesktopImg}
        alt="sky background desktop"
        loading="lazy"
      />
      <div className="flex flex-row flex-wrap -mt-2 ml-5 md:mt-24 justify-start md:justify-center gap-12 md:-ml-40 content-center">
        <div className="flex flex-col">
          <img
            className="h-64 md:h-96 w-72 md:w-1/4 absolute mt-4 ml-4 z-2"
            src={bookVancuver}
            alt="Vancuver_Ebook_Img"
            loading="lazy"
          />
          <img
            className="w-16 h-16 md:w-28 md:h-28 absolute z-3 mt-36 md:mt-12 ml-64 pr-2 md:pr-0 md:ml-80"
            src={payTag}
            alt="pay_tag_Img"
            loading="lazy"
          />
          <h3 className="text-xl md:text-2xl font-bold z-10 mt-72 md:mt-96 ml-24 text-center md:ml-72 self-center">
            Vancouver EBook
          </h3>
          <button
            product-id="12"
            onClick={handleClick}
            type="button"
            className="px-5 md:px-8 mt-4 ml-28 md:ml-80 z-6 relative leftBookbtn
            py-2.5
            bg-blue-600
            text-white
            font-medium
            text-xs
            leading-tight
            uppercase
            rounded
            shadow-md
            hover:bg-blue-700 hover:shadow-lg
            focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
            active:bg-blue-800 active:shadow-lg
            transition
            duration-150
            ease-in-out"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Buy Now!
          </button>
        </div>


        <div className="flex flex-col">
          <img
            className="h-64 md:h-96 w-72 md:w-1/4 absolute mt-4 ml-4 z-2"
            src={bookToronto}
            alt="Toronto_Ebook_Img"
            loading="lazy"
          />
          <img
            className="w-16 h-16 md:w-28 md:h-28 absolute z-3 mt-36 md:mt-12 ml-64 pr-2 md:pr-0 md:ml-80"
            src={payTag}
            alt="pay_tag_Img"
            loading="lazy"
          />
          <h3 className="text-xl md:text-2xl font-bold z-10 mt-72 md:mt-96 ml-32 md:ml-72 self-center">
            Toronto EBook
          </h3>
          <button
            product-id="11"
            onClick={handleClick}
            type="button"
            className="px-4 md:px-8 mt-4 md:ml-80 z-6 relative leftBookbtn ml-32
              py-2.5
              bg-blue-600
              text-white
              font-medium
              text-xs
              leading-tight
              uppercase
              rounded
              shadow-md
              hover:bg-blue-700 hover:shadow-lg
              focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
              active:bg-blue-800 active:shadow-lg
              transition
              duration-150
              ease-in-out"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Buy Now!
          </button>
        </div>
      </div>

      <div className="flex flex-row justify-center gap-32 mt-12 ">
        <EmailVarificationModal data={userData} handleClickFunc={handleClick} />
        <EmailVarificationModal data={userData} handleClickFunc={handleClick} />
      </div>

      <h2 className="blueText text-xl md:text-4xl text-center font-bold mt-2 md:mt-12">
        Useful Information you'll find in the EBook
      </h2>

      <div className="hidden md:flex md:flex-row flex-wrap justify-center content-baseline gap-10 mt-24 mb-64 px-1">
        <div className="flex flex-col justify-center mb-6 gap-5">
          <FontAwesomeIcon className="iconsPaySection" icon={faBriefcase} />
          <h3 className="text-md text-center font-bold">
            Employment <br /> and work
          </h3>
        </div>

        <div className="flex flex-col justify-center mb-6 gap-5">
          <FontAwesomeIcon className="iconsPaySection" icon={faSackDollar} />
          <h3 className="text-md text-center font-bold">
            Cost of <br /> living
          </h3>
        </div>

        <div className="flex flex-col justify-center mb-6 gap-5">
          <FontAwesomeIcon
            className="iconsPaySection -mt-4"
            icon={faSuitcaseMedical}
          />
          <h3 className="text-md text-center font-bold">Healthcare</h3>
        </div>

        <div className="flex flex-col justify-center mb-6 gap-5 -mt-3">
          <FontAwesomeIcon className="iconsPaySection" icon={faSchool} />
          <h3 className="text-md text-center font-bold">Education</h3>
        </div>

        <div className="flex flex-col justify-center mb-6 gap-5 -mt-3">
          <FontAwesomeIcon className="iconsPaySection" icon={faHouse} />
          <h3 className="text-md text-center font-bold">Finding a home</h3>
        </div>
      </div>


{/* mobile*/}
      <div className="grid grid-cols-3 my-3 block md:hidden">
        <div>
          <FontAwesomeIcon className="iconsPaySection" icon={faBriefcase} />
        </div>
        <div className="col-span-2 text-md justify-start font-bold">
          Employment and work
        </div>
        <div></div>
      </div>
      <div className="grid grid-cols-3 my-3 block md:hidden">
        <div>
          <FontAwesomeIcon className="iconsPaySection" icon={faSackDollar} />
        </div>
        <div className="col-span-2 text-md justify-start font-bold">
          Cost of living
        </div>
        <div></div>
      </div>
      <div className="grid grid-cols-3 my-3 block md:hidden">
        <div>
          <FontAwesomeIcon
            className="iconsPaySection"
            icon={faSuitcaseMedical}
          />
        </div>
        <div className="col-span-2 text-md justify-start font-bold">
          Healthcare
        </div>
        <div></div>
      </div>
      <div className="grid grid-cols-3 my-3 block md:hidden">
        <div>
          <FontAwesomeIcon className="iconsPaySection" icon={faSchool} />
        </div>
        <div className="col-span-2 text-md justify-start font-bold">
          Education
        </div>
        <div></div>
      </div>
      <div className="grid grid-cols-3 my-3 block md:hidden">
        <div>
          <FontAwesomeIcon className="iconsPaySection" icon={faHouse} />
        </div>
        <div className="col-span-2 text-md justify-start font-bold ">
          Finding a home
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default PaySection;
