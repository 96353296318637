import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../../../flexy.css"

function PaymentAdd() {
  return (
    <div className="program w_100 relative sp_padding_db">
            <div className="corner-ribbon top-left sticky red shadow">
              -50%<span className="sp_hide"> off</span>
            </div>
            <div className="w_100 program_name center color_black">
              <div className=''>Immigration Evaluation</div>
            </div>
            <div className="w_100 program_cost center">
              <div className="relative color_black margin_v">
                <p>
                  <span className="old_cost">&nbsp;$399&nbsp;</span>
                  <span className="cost">&nbsp;$199</span>
                  <span className="currency top">USD</span>
                </p>
              </div>
            </div>
            <div className="w_90 margin_a program_desc color_black">
              <div className="center submission_border"></div>
              <div className="center submission">
                <p className="review-redes-title">
                  <span>Immigration Evaluation</span>
                </p>
                <p className="left margin_v flex flex-row">
                  <div>
                    <FontAwesomeIcon icon="fa-solid fa-check" size="lg" style={{color: "#6ee64c",}} className='padding_hf_r w_5'/>
                  </div>
                  <div>
                   12-page profile evaluation, personally curated for you by a Registered Immigration Consultant
                  </div>
                </p>
                <p className="left margin_v flex flex-row">
                  <div>
                    <FontAwesomeIcon icon="fa-solid fa-check" size="lg" style={{color: "#6ee64c",}} className='padding_hf_r' />
                  </div>
                <span>

                  Assigned a personal Immigration Consultant within 24 hours
                </span>
                </p>
                <p className="left margin_v flex flex-row">
                  <div>
                    <FontAwesomeIcon icon="fa-solid fa-check" size="lg" style={{color: "#6ee64c",}} className='padding_hf_r'/>
                  </div>
                  <div>
                    Establish a strategic immigration plan
                  </div>
                </p>
                <p className="left margin_v flex flex-row">
                <div>
                  <FontAwesomeIcon icon="fa-solid fa-check" size="lg" style={{color: "#6ee64c",}} className='padding_hf_r'/>
                </div>
                <div>
                 Personalized immigration plan
                </div>
                </p>
                <p className="left margin_v flex flex-row">
                  <div>

                <FontAwesomeIcon icon="fa-solid fa-check" size="lg" style={{color: "#6ee64c",}} className='padding_hf_r'/>
                  </div>
                  <div>
                  Customized to increase your chances of approval
                  </div>
                </p>
              </div>
            </div>
          </div>
  )
}

export default PaymentAdd