import React from 'react';
import Hero from './Hero';
import PaySection from './PaySection';
import Benefits from './Benefits';
import Testimonials from '../../LandingPages/Testimonials';
import Footer from '../../LandingPages/Footer';
import '../MeetingLp/MeetingCssLp.css';



function MeetingLp() {
  return (

    <div className='main'>
      <Hero/>
      <PaySection/>
       <Benefits/>
      <Testimonials/>
      <Footer/>
    </div>
  
  )
  
}

export default MeetingLp