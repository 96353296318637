// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_contact__3gtcB{\n\tpadding-right: 2rem;\n\twidth: 45%;\n\tdisplay: inline-block;\n\tpadding: .25rem;\n\tdirection: rtl;\n}\n.Footer_logo__3rnxw{\n\theight: 5.5rem;\n\tpadding: 1rem;\n}\n.Footer_cardsLog__2_GNQ{\n\theight: 4.5rem;\n\tpadding: 1rem;\n}\n.Footer_footer__1NF-f{\n\tborder-top: 2px solid var(--white);\n\tbackground: black;\n}\n@media screen and (min-width: 768px){\n\t.Footer_footer__1NF-f{\n\t\t/*position: fixed;*/\n\t\t/*width: 100vw;*/\n\t\t/*bottom: 0;*/\n\t\t/*height: 3.5rem;*/\n\t}\n}\n.Footer_footer__1NF-f a{\n\ttext-decoration: none;\n}\n", "",{"version":3,"sources":["webpack://src/components/Footers/Footer.module.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;CACnB,UAAU;CACV,qBAAqB;CACrB,eAAe;CACf,cAAc;AACf;AACA;CACC,cAAc;CACd,aAAa;AACd;AACA;CACC,cAAc;CACd,aAAa;AACd;AACA;CACC,kCAAkC;CAClC,iBAAiB;AAClB;AACA;CACC;EACC,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,kBAAkB;CACnB;AACD;AACA;CACC,qBAAqB;AACtB","sourcesContent":[".contact{\n\tpadding-right: 2rem;\n\twidth: 45%;\n\tdisplay: inline-block;\n\tpadding: .25rem;\n\tdirection: rtl;\n}\n.logo{\n\theight: 5.5rem;\n\tpadding: 1rem;\n}\n.cardsLog{\n\theight: 4.5rem;\n\tpadding: 1rem;\n}\n.footer{\n\tborder-top: 2px solid var(--white);\n\tbackground: black;\n}\n@media screen and (min-width: 768px){\n\t.footer{\n\t\t/*position: fixed;*/\n\t\t/*width: 100vw;*/\n\t\t/*bottom: 0;*/\n\t\t/*height: 3.5rem;*/\n\t}\n}\n.footer a{\n\ttext-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contact": "Footer_contact__3gtcB",
	"logo": "Footer_logo__3rnxw",
	"cardsLog": "Footer_cardsLog__2_GNQ",
	"footer": "Footer_footer__1NF-f"
};
export default ___CSS_LOADER_EXPORT___;
