import React, { useRef } from 'react';
import { API_ADDRESS, API_VERSION } from '../../state/variables';

function EmailVarifyModal({ data }) {
  const inputRef = useRef(null);
  const getEmail = () => {
    //get email from user
    data.email = inputRef.current.value;
    //regex for email
    const emailRegexp = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      'i'
    );

    //chack if email is ok
    if (emailRegexp.test(data.email) === true) {
      const jsonData = JSON.stringify({ data });

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application / json',
          'Access-Control-Allow-Origin': '*'
        },
        body: jsonData
      };

      //send daa to server and get the link for safe charge.
      fetch(API_ADDRESS + API_VERSION + '/LPBackend/getDataforUrl.php',
         requestOptions
        // 'http://localhost/test/LPBackend/getDataforUrl.php',
        // requestOptions
      )
        .then((response) => response.json())
        // .then((data) => console.log(data)); // access json.body here

        .then((data) => {
          // console.log(data);
          window.location.replace(data);
          // let frame = document.createElement('iframe');
          // let Iframe = document.getElementById('Iframe')
          // console.log(Iframe);
          // Iframe.appendChild(frame);
          // frame.setAttribute('src', data);
          // frame.setAttribute('id', 'theIframe');
          // document.getElementById("theIframe").style.width = "800px";
          // document.getElementById("theIframe").style.height = "800px";
          // document.getElementById("theIframe").style.margin = "10%";
        }
        )
    } else {
      alert('WRONG EMAIL TRAY AGAIN');
    }
  };

  return (
    <>
      <div
        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog w-auto pointer-events-auto">
          <div className="modal-content border-none shadow-lg flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
          
              
              
              <h5
                className="text-xl font-medium leading-normal text-gray-800"
                id="exampleModalLabel"
              >
                Please Confirm your email
              </h5>
              <button
                type="button"
                className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body relative p-4">
              <div className="flex justify-center">
                <div className="mb-3 xl:w-96">
                  <label
                    htmlFor="userEmail"
                    className="form-label inline-block mb-2 text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    ref={inputRef}
                    type="text"
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none "
                    id="userEmail"
                    placeholder="Your email"
                  />
                </div>
              </div>
            </div>
            <div className='flex flex-col flex-wrap justify-center' id="Iframe"></div>
            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
              <button
                type="button"
                className="px-6
                 py-2.5
                 bg-purple-600
                 text-white
                 font-medium
                 text-xs
                 leading-tight
                 uppercase
                 rounded
                 shadow-md
                 hover:bg-purple-700 hover:shadow-lg
                 focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0
                 active:bg-purple-800 active:shadow-lg
                 transition
                 duration-150
                 ease-in-out"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                onClick={getEmail}
                type="button"
                className="px-6
                 py-2.5
                 bg-blue-600
                 text-white
                 font-medium
                 text-xs
                 leading-tight
                 uppercase
                 rounded
                 shadow-md
                 hover:bg-blue-700 hover:shadow-lg
                 focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                 active:bg-blue-800 active:shadow-lg
                 transition
                 duration-150
                 ease-in-out
                 ml-1"
                lead-email="lead-email"
              >
                confirm email
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailVarifyModal;
