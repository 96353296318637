import React, { useState,useCallback} from "react";
import "../../../../src/flexy.css";
import "./payment-assessment.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import PaymentAdd from "./PaymentAdd";
import PaymentForm from "./PaymentForm";
import Assment from "./AssessmentQuestionnaire_radioBtn";
import SelectQuestions from "./SelectQuestions";

function PaymentCarusel() {
  const [current, setCurrent] = useState(0); // Set initial state
  const [data, setUserData] = useState({userInfo:null,AssmentQuestionier:null});
  const [displayFirst, setDisplayFirst] = useState("block");

  // State to track if button has been clicked
  const [clicked, setClicked] = useState(false);

  // Function to hide first div when button is clicked
  const hideBtn = () => {
    if (!clicked) {
      setDisplayFirst("none");
      setClicked(true);
    }
  };

  const nextSlide = () => {
    setCurrent(current === components.length-1 ? 0 : current + 1);
    hideBtn();
  };

  const scrollToSection = () => {
    // Replace 'sectionId' with the actual ID of the section you want to scroll to
    const sectionId = 'benefits';
    const section = document.getElementById(sectionId);

    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth', // Optional: Add smooth scrolling animation
      });
    }
  }
  
  const updateUserInfo = useCallback((userInfo) => {
    setUserData(prevData => ({ ...prevData, userInfo }));
}, [setUserData]);

const updateAssmentQuestionier = useCallback((AssmentQuestionier) => {
    setUserData(prevData => ({ ...prevData, AssmentQuestionier }));
}, [setUserData]);


  const components = [
    <PaymentAdd />,
    <PaymentForm updateUser={updateUserInfo} caruselNext={nextSlide}/>,
    <Assment  updateUser={updateAssmentQuestionier} caruselNext={nextSlide}/>,
    <SelectQuestions fullUserData={data}/>,
  ];

  return (
    <>
      <Carousel
        style={{ backgroundColor: "transparent" }}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        infiniteLoop={false}
        autoPlay={false}
      >
        <div>
          <div className="w_90 sp_w_95 margin_a programs padding_db_t">
            {components[current]}
          </div>
          <button
            className="nextBtn"
            onClick={nextSlide}
            style={{ display: displayFirst }}>
            Get Started
          </button>
        </div>
      </Carousel>
      <div>
      <button
            className="more_infoBtn"
            onClick={scrollToSection }
            style={{ display: displayFirst }}>
            More Info
          </button>
      </div>
    </>
  );
}

export default PaymentCarusel;
