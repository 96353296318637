import s from './Footer.module.css';
import iccrc from '../../images/iccrc.png';
import visa from '../../images/mastercard.png';
import mastercard from '../../images/visa.png';
import * as c from '../../state/variables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleUp } from '@fortawesome/free-solid-svg-icons';
const Footer = () => {


	const scrollToSection = () => {
		// Replace 'sectionId' with the actual ID of the section you want to scroll to
		const sectionId = 'mainHeader';
		const section = document.getElementById(sectionId);
	
		if (section) {
		  window.scrollTo({
			top: section.offsetTop,
			behavior: 'smooth', // Optional: Add smooth scrolling animation
		  });
		}
	}

	return <footer className={s.footer} id='mainFooter'>
		<div className="max_width_custom">
			<div className="w_50 inline terms sp_w_100 sp_padding_t">
				<div className="w_50 center inline">
					<a className="color_white" href="/terms-of-service">Terms of Service</a>
				</div>
				<div className="w_50 center inline">
					<a className="color_white" href="/privacy-policy">Privacy Policy</a>
				</div>
			</div>
			<div className="w_50 inline sp_w_50 middle center">
				<img className={s.logo} src={iccrc} alt={'img'}/>
			</div>
			<div className="w_50 inline sp_w_25 middle center">
				<img className={s.cardsLog} src={mastercard} alt="" srcset="" />
			</div>
			<div className="w_50 inline sp_w_25 middle center">
				<img className={s.cardsLog} src={visa} alt="" srcset="" />
			</div>
			<div className="w_100 middle f_07 padding_hf_v color_white center">The website {c.DOMAIN} is owned
				and operated by CAIC IMMIGRATION CL. Calle Balmes 173, 08006, Barcelona, Spain.
			</div>
			<button onClick={scrollToSection}>
				<FontAwesomeIcon icon={faCircleUp} style={{color: "#68cc3e",}} />

			</button>
		</div>
	</footer>
}
export default Footer;
