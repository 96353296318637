import React from 'react';
import HamburgerMenu  from '../HamburgerMenu';
import bgHero from "./img/heroImage.jpg";
function Hero() {
  return (
    <>
      <div>
        <img
          className="bg absolute hidden md:block"
          src={bgHero}
          alt="Handsome man"
          loading="lazy"
        />
        <img
          className="object-cover h-56 w-full absolute block md:hidden"
          src={bgHero}
          alt="Handsome man"
          loading="lazy"
        />

        <HamburgerMenu/>

        <div className="flex flex-col md:heroText ml-4 md:ml-40 lg:ml-96 xl:mt-48 lg:mt-24 md:mt-20 mt-5 z-7 relative">
          <h1 className="xl:text-5xl lg:text-3xl text-lg font-bold text-white">
            Book an immigration <br />
            Consultation <span className="subHedline">TO CANADA</span>
          </h1>
          <p className="xl:text-2xl text-md text-white xl:mt-4 mt-1 pt-4">
            Understand everything about moving to <br />
            Canada with our professional consultants. 
          </p>
        </div>
      </div>
    </>
  );
}

export default Hero;
