export const API_ADDRESS = "https://www.canada-direct.com/php/api/"
export const API_VERSION = "1.0"
export const IPGEOL = "40d80f5812254e4ab3cf5dae10266730"

// REACT_APP_DOMAIN='www.joincanadanow.ca' REACT_APP_SUPPORT_EMAIL='support@joincanadanow.ca' REACT_APP_BRAND='Join Canada' REACT_APP_PHONE_NO='1-778-3003593' REACT_APP_FACEBOOK_PIXEL_ID='256660015688430' REACT_APP_WHATS_APP='256660015688430' npm start
export const DOMAIN = process.env.REACT_APP_DOMAIN
export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL
export const BRAND = process.env.REACT_APP_BRAND
export const PHONE_NO = process.env.REACT_APP_PHONE_NO
export const FACEBOOK_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID
export const WHATS_APP = process.env.REACT_APP_WHATS_APP


export const STEPS = [{id: 1,text: 'Eligibility'},{id: 2,text: 'Registration'},{id: 3,text: 'Conformation'}]
