import React from "react";
import Benefits from "../MeetingLp/Benefits";
import Testimonials from "../../LandingPages/Testimonials";
import "../../../../src/flexy.css";
import "./payment-assessment.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import  PaymentCarusel from "./PaymentCarusel";
function PaymentLp() {


  return (
    <div className="main">
    < PaymentCarusel/>
      <div className="bgMainColor">
        <iframe
        className="border-none p-3"
          src="https://player.vimeo.com/video/835799293?h=f8557bb0ee&title=0&byline=0&portrait=0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen title="aboutVidei">
         </iframe>
      </div>
      <Benefits id="benefits"/>
      <div className="mt-12 bgMainColor"></div>
      <Testimonials className='bgMainColor'/>
    </div>
  );
}

export default PaymentLp;
