import s from './FooterHomePage.module.css';
import visa from '../../../images/visa.png'
import mastercard from '../../../images/mastercard.png'
import * as c from '../../../state/variables'


const FooterHomePage = () => {
	return (
	<div>
		<div className={s.footer} id='mainFooter'>
			<div className="max_width_custom">
				<div className="w_50 sp_w_100 inline color_white top">
					<div className="margin_db">
						<div className="w_100 color_red"><h2>Menu:</h2></div>
						<div className="w_100 padding_t"><a className="color_white scrollLink" href="/registration">Check
							eligibility</a></div>
						<div className="w_100 padding_t"><a className="color_white scrollLink" href="#expertise">Our
							expertise</a></div>
						<div className="w_100 padding_t"><a className="color_white scrollLink" href="#about">About us</a>
						</div>
						<div className="w_100 padding_t"><a className="color_white scrollLink" href="#whyus">Why choose
							us</a></div>
						<div className="w_100 padding_t"><a className="color_white scrollLink" href="#contact">Contact
							us</a></div>
					</div>
				</div>
				<div className="w_50 sp_w_100 inline color_white">
					<div className="margin_db_v padding_db_h border_l sp_border_none">
						<div className="w_100 color_red"><h2>Contact Us:</h2></div>
						<div className="w_100 padding_t"><b>CAIC IMMIGRATION SL ({c.BRAND})</b></div>
						<div className="w_100 padding_t">Phone: <a className={s.footerLink} href={"tel:"+c.PHONE_NO}>
							<span className="color_white">{c.PHONE_NO}</span>
						</a></div>
						<div className="w_100 padding_t">Email: <a className={s.footerLink} href={"mailto:" + c.SUPPORT_EMAIL}>
							<span className="color_white padding_hf_h">{c.SUPPORT_EMAIL}</span>
						</a></div>
						<div className="w_100 padding_t"><b>Our Address:</b></div>
						<div className="w_100 padding_t"><b>Canada:</b> #700-838 W Hastings Street</div>
						<div className="w_100 padding_t">Vancouver, British Columbia V6C0A6 Canada (Legal procedures).</div>
						<div className="w_100 padding_t"><b>United Kingdom:</b> 83 Baker Street. London, W1U 6AG, GBR</div>
						<div className="w_100 padding_t"><b>Spain:</b> Calle Balmes 173, 08006, Barcelona, Spain.</div>
						<div className="w_100 padding_t"><b>Israel:</b> Kineret 5, Bnei Brak, Israel (Payment Solutions).
						</div>
						<div className="w_100 padding_t"><img className={s.footerVisaMastercard} src={visa}/><img
							src={mastercard} className={s.footerVisaMastercard}/></div>
					</div>
				</div>
			</div>
		</div>
	</div>)
}
export default FooterHomePage;
