import s from './Header.module.css';
// import ReactPixel from 'react-facebook-pixel';
// import callImg from '../../images/phone.png';
// import mailImg from '../../images/mail.png';
// import * as c from "../../state/variables";
import mainImg from "../../components/LandingPages/PaymentPageLp/header.png"

const Header = () => {
	return <header className={s.header} id="mainHeader">
			<div className='main_image1'><img src={mainImg} alt="" srcset="" /></div>
		<link rel='stylesheet' id='oceanwp-google-font-montserrat-css'  href='https://fonts.googleapis.com/css?family=Montserrat%3A100%2C200%2C300%2C400%2C500%2C600%2C700%2C800%2C900%2C100i%2C200i%2C300i%2C400i%2C500i%2C600i%2C700i%2C800i%2C900i&amp;subset=latin&amp;ver=5.1.1' type='text/css' media='all' />
		 <div className={s.divHeader}>
			<p className={s.textOnImg}><b>Helping<br></br>People<br></br><span className={s.yellowText}>Immigrate</span>
				</b></p>
			<p className={s.mainText}>At CFIS Immigration, our experts  will <br></br>handle all of your immigration needs<br></br> 
			through and through. With our unique <br></br> approach, our team of RCIC’s will provide <br></br>you with personalized assistance,
			<br></br> consulting, and advising when it comes <br></br>to all temporary and permanent <br></br>resident applications</p>
			
		</div> 
	</header>
}
export default Header;
