import React, { useState } from 'react';
import s from "../../Registration/RegistrationForm/MonthlyIncomeForm/MonthlyIncomeForm.module.css";

const AssessmentQuestionnaire_radioBtn = ({caruselNext,updateUser}) => {
  const questions = [{id :'q01speekFrench',label:'Do you speak French'},
    {id :'q02areYouACareGiver',label:'Are You a Caregiver?'},
    {id :'q03areYouANurse',label:'Are You A Nurse?'},
    {id :'q04areYouABusinessOwner',label:'Are You A Business Owner?'},
    {id :'q05doYoHaveKids',label:'Do You Have Kids?'},
    {id :'q06areYouSingle',label:'Are You Single?'},
    {id :'q07haveYouAppliedForEEinThePast',label:'Have You Applied For Express Entry In The Past?'},
    {id :'q08AreYouDivorsed',label:'Are You Divorced?'},
    {id :'q09AreYouInCommonLawRelationship',label:'Are You In Common-Law Relationship?'},
    {id :'q10DoYouHaveFamilyInCanada',label:'Do You Have Family In Canada?'}, 
    {id :'q11areYouSelfEmployee',label:'Are You Self-Employee?'},
    {id :'q12beenInCanada',label:'Have you been in Canada?'}];
  

  const initialAnswers = {};
  questions.forEach(question => {
    initialAnswers[question.id] = false;  // initialize all answers as not checked
  });

  const [answers, setAnswers] = useState(initialAnswers);
  const handleChange = (event) => {
    setAnswers({...answers,[event.target.name]: event.target.checked  // event.target.checked is either true or false
    });
    
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    updateUser(answers);
    caruselNext();
  }

  return (
    <>
      <div className="mb-4 text-xl font-bold color_red" id='questionier'>
      Assessment  Questionnaire
      </div>
      <form onSubmit={handleSubmit}>
        {questions.map(question => (
          <div id={s.formSwitchBtn} key={question.id} className="form-check form-switch">
            <input 
              type="checkbox" 
              id={question.id}
              name={question.id}
              checked={answers[question.id]}  // this will either be true or false
              onChange={handleChange} 
              className="form-check-input"
            />
            <label className='left margin_v flex flex-row' id={s.formCheckabel} htmlFor={question.id}>{question.label}</label>
          </div>
        ))}
         <div>
            <input type="submit" value="Continue" className={s.contiuneBtn} />
          </div>
      </form>
    </>
  );
};

export default AssessmentQuestionnaire_radioBtn;