import s from './MonthlyIncomeForm.module.css';
import '../../../../state/params';
import store, {checkData, updateData, removeAllRedAlerts} from '../../../../state/state'
import {utm_source, utm_medium, utm_campaign, utm_term, url,url2,refer} from "../../../../state/params";
import {useState, useEffect} from 'react';
import {MyComponent} from '../../../../state/CountriesList';
import {CountryCode} from '../../../../state/getCountryCode';
import 'react-intl-tel-input/dist/main.css';
import classes from "../../Registration.module.css";
import axios from "axios";
import * as c from "../../../../state/variables";




const MonthlyIncomeForm = (props) => {

	function checkPhone(){

	}

	function validateData(){

		document.getElementById('checkBtn').style.pointerEvents = 'none';
		document.getElementById('checkBtn').onclick = false;
		console.clear()
		const data = props.state.registrationData
		data.Phone = document.getElementById("prefix").value + document.getElementById("Phone").value
		// console.log(data)
		let countrer_error_fields = 0;
		let cl = classes.alert_border
		for(const [key, value] of Object.entries(data)){
			if(key === 'LeadType') continue;
			if(key === 'Savings') continue;
			if(value === ''){
				countrer_error_fields ++
				document.getElementById(key).classList.add(cl)
			}
		}
		if(countrer_error_fields > 0){
			// console.log(countrer_error_fields)
			alert("Please fill all empty fields!")

		}else{
			checkData()
			// console.log("ALL GOOD")
		}
		setTimeout(function(){
			removeAllRedAlerts()
			document.getElementById('checkBtn').style.pointerEvents = 'auto';
			document.getElementById('checkBtn').onclick = true;
		}, 1000)
	}

	const [First_Name, setFirstName] = useState(props.state.registrationData.First_Name)
	const [Last_Name, setLastName] = useState(props.state.registrationData.Last_Name)
	const [Email, setEmail] = useState(props.state.registrationData.Email)
	const [Where_Do_You_Live, setWhere_Do_You_Live] = useState(props.state.registrationData.Where_Do_You_Live)
	const [Phone, setPhone] = useState(props.state.registrationData.Phone)
	// const [Birth_Country, setBirth_Country] = useState(props.state.registrationData.Birth_Country)
	// const [Age, setAge] = useState(props.state.registrationData.Age)
	// const [Marital_Status, setMarital_Status] = useState(props.state.registrationData.Marital_Status)
	// const [Speaking_English_Level, setSpeaking_English_Level] = useState(props.state.registrationData.Speaking_English_Level)
	// const [Education_Level, setEducation_Level] = useState(props.state.registrationData.Education_Level)
	// const [Been_in_Canada, setBeen_in_Canada] = useState(props.state.registrationData.Been_in_Canada)
	// const [Monthly_income_USD, setMonthly_income_USD] = useState(props.state.registrationData.Monthly_income_USD)
	// const [What_visa_are_you_interested_in, setWhat_visa_are_you_interested_in] = useState(props.state.registrationData.What_visa_are_you_interested_in)
	// const [When_would_you_like_to_immigrate, setWhen_would_you_like_to_immigrate] = useState(props.state.registrationData.When_would_you_like_to_immigrate)
	// const [Business_Owner, setBusiness_Owner] = useState(props.state.registrationData.Business_Owner)
	const [Referrer_Link, setReferrer_Link] = useState(refer)
	const [UTM_Source, setUTM_Source] = useState(utm_source)
	const [FB_Campaign_Name, setFB_Campaign_Name] = useState(utm_medium)
	const [FB_Adset_Name, setFB_Adset_Name] = useState(utm_campaign)
	const [FB_Ad_Name, setFB_Ad_Name] = useState(utm_term)

	useEffect(()=>{updateData('First_Name', First_Name);}, [First_Name])
	useEffect(()=>{updateData('Last_Name', Last_Name);}, [Last_Name])
	useEffect(()=>{updateData('Email', Email);}, [Email])
	useEffect(()=>{updateData('Where_Do_You_Live', Where_Do_You_Live);}, [Where_Do_You_Live])
	useEffect(()=>{updateData('Phone', Phone);}, [Phone])
	// useEffect(()=>{updateData('Birth_Country', Birth_Country);}, [Birth_Country])
	// useEffect(()=>{updateData('Age', Age);}, [Age])
	// useEffect(()=>{updateData('Marital_Status', Marital_Status);}, [Marital_Status])
	// useEffect(()=>{updateData('Speaking_English_Level', Speaking_English_Level);}, [Speaking_English_Level])
	// useEffect(()=>{updateData('Education_Level', Education_Level);}, [Education_Level])
	// useEffect(()=>{updateData('Been_in_Canada', Been_in_Canada);}, [Been_in_Canada])
	// useEffect(()=>{updateData('Monthly_income_USD', Monthly_income_USD);}, [Monthly_income_USD])
	// useEffect(()=>{updateData('What_visa_are_you_interested_in', What_visa_are_you_interested_in);}, [What_visa_are_you_interested_in])
	// useEffect(()=>{updateData('When_would_you_like_to_immigrate', When_would_you_like_to_immigrate);}, [When_would_you_like_to_immigrate])
	// useEffect(()=>{updateData('Business_Owner', Business_Owner);}, [Business_Owner])
	useEffect(()=>{updateData('Referrer_Link', Referrer_Link);}, [Referrer_Link])
	useEffect(()=>{updateData('UTM_Source', UTM_Source);}, [UTM_Source])
	useEffect(()=>{updateData('FB_Campaign_Name', FB_Campaign_Name);}, [FB_Campaign_Name])
	useEffect(()=>{updateData('FB_Adset_Name', FB_Adset_Name);}, [FB_Adset_Name])
	useEffect(()=>{updateData('FB_Ad_Name', FB_Ad_Name);}, [FB_Ad_Name])
	return(
		<div>

			<form>
				<div className={s.form_fields + ' w_90 margin_a sp_w_95 form_wrapper form_fields'}>
					<div className={s.check + ' w_100 padding_db_v color_red realtive'}>
						<img src={"/img/Registration/arrow.png"} alt={'arrow'}/>CHECK YOUR ELIGIBILITY
					</div>
					<div className="w_50 center sp_w_100 padding_b inline">
						<input id="First_Name"
							   onChange={(e)=>setFirstName(e.target.value)}
							   className={s.inputField}
							   type="text"
							   name="First_Name"
							   placeholder="First name"
							   required
								/>
					</div>
					<div className="w_50 center sp_w_100 inline padding_b">
						<input id="Last_Name"
							   onChange={(e) => setLastName(e.target.value)}
							   className={s.inputField}
							   type="text"
							   name="Last_Name"
							   placeholder="Last Name"
							   required
							   />
					</div>
					<div className="w_50 center sp_w_100 padding_b inline">
						<input 	id="Email"
								className={s.inputField}
							  	onChange={(e)=>setEmail(e.target.value)}
								type="email"
								name="Email"
								placeholder="Email"
								required={true}
								/>
					</div>
					<div className="w_50 center sp_w_100 inline padding_b">
						<select id="Where_Do_You_Live"
								className={s.select_field}
								name="Where_Do_You_Live"
								required="required"
								onChange={(e)=>{CountryCode(document.getElementById('Where_Do_You_Live').value);
								
								setWhere_Do_You_Live(e.target.value)}}
								>
							<option value="">Where Do You Live</option>
							{MyComponent()}
						</select>
					</div>
					<div className="w_50 center sp_w_100 padding_b inline">
						<div className="w_100">
								<input type="tel"
									id="prefix"
								   className={s.inputFieldPrefix}
									   disabled={true}
								/>
								<input id="Phone"
									   className={s.inputFieldPhone}
									   type="tel"
									   name="Phone"
									   onChange={(e)=>setPhone(e.target.value)}
									   placeholder="&nbsp;Your Best Phone Number"
									   required
									   onBlur={checkPhone}
								/>
						</div>
					</div>
					{/* <div className="w_50 center sp_w_100 padding_b inline">
						<select id="Birth_Country"
								className={s.select_field}
								name="Birth_Country"
								onChange={(e)=>setBirth_Country(e.target.value)}
								required
								>
							<option value="">Birth Country</option>
							{MyComponent()}
						</select>
					</div> */}
					{/* <div className="w_50 center sp_w_100 padding_b inline">
						<input id="Age"
								className={s.inputField}
							    onChange={(e)=>setAge(e.target.value)}
								type="tel"
								name="Age"
								placeholder="Age"
								required
								maxLength="3"
								minLength="2"
								/>
					</div> */}
					{/* <div className="w_50 center sp_w_100 inline padding_b">
						<select
								id="Marital_Status"
								onChange={(e)=>setMarital_Status(e.target.value)}
								className={s.select_field}
								name="Marital_Status"
								required
								>
							<option value="">Marital Status</option>
							<option value="common-law marriage">Common-law marriage</option>
							<option value="Divorced">Divorced</option>
							<option value="Legally separated">Legally separated</option>
							<option value="Married">Married</option>
							<option value="Single">Single</option>
							<option value="Widowed">Widowed</option>
						</select>
					</div> */}
					{/* <div className="w_50 center sp_w_100 padding_b inline">
						<select
								id="Speaking_English_Level"
								className={s.select_field}
								name="Speaking_English_Level"
								onChange={(e)=>setSpeaking_English_Level(e.target.value)}
								required
								>
							<option value="">Speaking English Level</option>
							<option value="No English">No English</option>
							<option value="Basic">Basic</option>
							<option value="Medium">Medium</option>
							<option value="Advanced">Advanced</option>
							<option value="Proficient">Proficient</option>
						</select>
					</div> */}
					{/* <div className="w_50 center sp_w_100 inline padding_b">
						<select
								id="Education_Level"
								className={s.select_field}
								name="Education_Level"
								onChange={(e)=>setEducation_Level(e.target.value)}
								required
								>
							<option value="">Education Level</option>
							<option value="Primary school only">Primary school only</option>
							<option value="High School Degree">High School Degree</option>
							<option value="Vocational School">Vocational School</option>
							<option value="University Degree">University Degree</option>
							<option value="Master Degree">Master Degree</option>
							<option value="Doctorate Degree">Doctorate Degree</option>
							<option value="Other">Other</option>
						</select>
					</div> */}
					{/* <div className="w_50 center sp_w_100 padding_b inline">
						<select
								id="Been_in_Canada"
								className={s.select_field}
								name="Been_in_Canada"
								onChange={(e)=>setBeen_in_Canada(e.target.value)}
								required
								>
							<option value="">Have you been in Canada?</option>
							<option value="No">No</option>
							<option value="Yes">Yes</option>
						</select>
					</div> */}
					{/* <div className="w_50 center sp_w_100 inline padding_b">
						<select
								id="Monthly_income_USD"
								className={s.select_field}
								name="Monthly_income_USD"
								onChange={(e)=>setMonthly_income_USD(e.target.value)}
								required
								>
							<option value="">Monthly income in American Dollars</option>
							<option value="$0-1,200">$0-1,200</option>
							<option value="$1,200-2,000">$1,200-2,000</option>
							<option value="$2,000-3,000">$2,000-3,000</option>
							<option value="$3,000-5,000">$3,000-5,000</option>
							<option value="$5,000-8,000">$5,000-8,000</option>
							<option value="$8,000-15,000">$8,000-15,000</option>
							<option value="$15,000+">$15,000+</option>
						</select>
					</div> */}
					{/* <div className="w_50 center sp_w_100 padding_b inline">
						<select id="What_visa_are_you_interested_in"
								className={s.select_field}
								name="What_visa_are_you_interested_in"
								onChange={(e)=>setWhat_visa_are_you_interested_in(e.target.value)}
								required
							>
							<option value="">Which visa are you interested</option>
							<option value="Permanent Residency Visa">Permanent Residency Visa</option>
							<option value="Work Visa">Work Visa</option>
							<option value="Student Visa">Student Visa</option>
							<option value="Working Holiday (IEC)">Working Holiday (IEC)</option>
							<option value="Tourist">Tourist</option>
						</select>
					</div> */}
					{/* <div className="w_50 middle center sp_w_100 inline padding_b">
						<select id="When_would_you_like_to_immigrate"
								className={s.select_field}
								name="When_would_you_like_to_immigrate"
								onChange={(e)=>setWhen_would_you_like_to_immigrate(e.target.value)}
								required>
							<option value="">When would you like to immigrate?</option>
							<option value="0-1 Year">As soon as possible</option>
							<option value="1-2 Years">1-2 Years</option>
							<option value="2-3 Years">2-3 Years</option>
							<option value="3-5 Years">3-5 Years</option>
							<option value="5+">5+ Years</option>
						</select>
					</div> */}
					{/* <div className="w_50 middle center padding_b sp_w_100 inline">
						<select
							id="Business_Owner"
							className={s.select_field}
							name="Business_Owner"
							onChange={(e)=>setBusiness_Owner(e.target.value)}
							required>
							<option value="">Are You Business Owner?</option>
							<option value="Yes">Yes</option>
							<option value="No">No</option>
						</select>
					</div> */}
					<div className="w_50 middle center sp_w_100 inline padding_b">
						<div className="w_80 margin_a">
							<label><input className="input"
										  type="checkbox"
										  value="1"
										  required
										  aria-invalid="false"
										  checked="true"
									/><span>Yes, I agree to the <a
								href="/terms-of-service" target="_blank">Terms of Service </a> and <a
								href="/privacy-policy" target="_blank">Privacy Policy </a>and would like to receive my login details on Email.</span></label>
						</div>
					</div>
					<div className="w_50 center sp_w_100 sp_padding_b inline">
						<div className="w_85 margin_a">
							<div
								id="checkBtn"
								disabled={true}
								onClick={validateData}
								className={s.check_btn + " button check_btn margin_a sp_w_80 color_white button_red bg_red w_85 center uppercase padding"}>
								<span className="ladda-label">Check now</span></div>
						</div>
					</div>
					<div className="hide">
						<input type="text" name="Referrer_Link" id="Referrer_Link" value={utm_source}/>
						<input type="text" name="UTM_Source" id="UTM_Source" value={utm_source}/>
						<input type="text" name="FB_Campaign_Name" id="FB_Campaign_Name" value={utm_campaign}/>
						<input type="text" name="FB_Adset_Name" id="FB_Adset_Name" value={utm_term}/>
					</div>
				</div>
			</form>
		</div>
	)
}
export default MonthlyIncomeForm;
