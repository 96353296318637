import React, { useState } from "react";
import companyLogo from "../LandingPages/MeetingLp/img/logo2.png";
const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
  <>
    <a href="/meetings">
    {" "}
    <img
      className="absolute h-7 w-16 md:h-12 md:w-24 md:ml-36 m-4"
      src={companyLogo}
      alt="LOGO"
      loading="lazy"
      />
  </a>
  <nav className=" md:block hidden heroSection h-12 md:h-20 flex flex-row justify-end"> 
  <ul className="flex md:flex-row flex-col justify-center md:justify-end pt-4 md:mb-2">
          <li className="xl:mx-24 lg:mx-8 mx-4">
            <a className="text-white text-md" href="#benefits">
              Benefits
            </a>
          </li>

          <li className="xl:mx-24 lg:mx-8 mx-4">
            <a className="text-white text-md" href="#testimonials">
              Testimonials
            </a>
          </li>
        </ul>
        </nav>
    <nav className="md:hidden block heroSection h-12 md:h-20 flex flex-row justify-end"> 
      {isOpen && (
        <ul className="flex md:flex-row flex-col justify-center md:justify-end pt-16 md:mb-2 bgHeader">
          <li className="xl:mx-24 lg:mx-8 mx-4">
            <a className="text-white text-md font-bold" href="#benefits">
              Benefits
            </a>
          </li>

          <li className="xl:mx-24 lg:mx-8 mx-4">
            <a className="text-white text-md font-bold" href="#testimonials">
              Testimonials
            </a>
          </li>
        </ul>
        
      )}
      <button className="m-5 md:hidden block" onClick={() => setIsOpen(!isOpen)}>
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="bars"
          className="w-6"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          >
          <path
            fill="white"
            d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
            ></path>
        </svg>
      </button>   
    </nav>
      </>
  );
};

export default HamburgerMenu;
