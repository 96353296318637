// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../LandingPages/PaymentPageLp/headerImg2.jpg";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../LandingPages/PaymentPageLp/header.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StepsHeader_info__2-Ecx{\n    /* background-image: url('process.png'); */\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: cover;\n    filter: brightness(40%);\n}\n.StepsHeader_info__2-Ecx .StepsHeader_fill_text__aDBXR{\n    font-size: 1.4em;\n    font-weight: 600;\n}\n.StepsHeader_info__2-Ecx .StepsHeader_fill_text_good_luck__l01uU{\n    font-size: 2.5rem;\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n}\n.StepsHeader_main_image1__1-vgv{\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    background-repeat: no-repeat;\n    max-width: 47% !important;\n    width: 47% !important;\n}", "",{"version":3,"sources":["webpack://src/components/DifferenceComponents/StepsHeader/StepsHeader.module.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,yDAAkF;IAClF,4BAA4B;IAC5B,sBAAsB;IACtB,uBAAuB;AAC3B;AACA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,yDAA8E;IAC9E,4BAA4B;IAC5B,yBAAyB;IACzB,qBAAqB;AACzB","sourcesContent":[".info{\n    /* background-image: url('process.png'); */\n    background-image: url('/src/components/LandingPages/PaymentPageLp/headerImg2.jpg');\n    background-repeat: no-repeat;\n    background-size: cover;\n    filter: brightness(40%);\n}\n.info .fill_text{\n    font-size: 1.4em;\n    font-weight: 600;\n}\n.info .fill_text_good_luck{\n    font-size: 2.5rem;\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n}\n.main_image1{\n    background-image: url('/src/components/LandingPages/PaymentPageLp/header.png');\n    background-repeat: no-repeat;\n    max-width: 47% !important;\n    width: 47% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": "StepsHeader_info__2-Ecx",
	"fill_text": "StepsHeader_fill_text__aDBXR",
	"fill_text_good_luck": "StepsHeader_fill_text_good_luck__l01uU",
	"main_image1": "StepsHeader_main_image1__1-vgv"
};
export default ___CSS_LOADER_EXPORT___;
